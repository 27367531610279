var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hello",style:('width:' +
    _vm.barwidthL +
    '%;height:' +
    _vm.barheight +
    'px;margin:0 auto;padding:20px 0;transition:0.3s')},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAnswer() != 0),expression:"isAnswer() != 0"}],style:('width:' +
      _vm.barwidthL +
      '%;height:' +
      _vm.barheight +
      'px;bottom:' +
      _vm.reSizeMainBottom +
      'px'),attrs:{"id":"main"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAnswer() == 0),expression:"isAnswer() == 0"}],staticClass:"noData"},[_c('p',[_c('svg-icon',{staticClass:"empty-icon",attrs:{"icon-class":"empty-data"}}),_c('br'),_vm._v(_vm._s(_vm.$t("board['暫無數據']"))+" ")],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }