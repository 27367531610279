<template>
  <!--<div class="hello" style="width:100%; height:195px;margin:0 auto;padding:20px 0">-->
  <div
    class="hello"
    :style="
      'width:' +
      barwidthL +
      '%;height:' +
      barheight +
      'px;margin:0 auto;padding:20px 0;transition:0.3s'
    "
  >
    <!--<div class="topic_box">-->
    <!--<p class="topic_title">题目：</p>-->
    <!--<div class="topic_content">题目内容</div>-->
    <!--</div>-->
    <!--<button  @click="drawLine(1)">变色</button>-->
    <div
      v-show="isAnswer() != 0"
      id="main"
      :style="
        'width:' +
        barwidthL +
        '%;height:' +
        barheight +
        'px;bottom:' +
        reSizeMainBottom +
        'px'
      "
    ></div>
    <div class="noData" v-show="isAnswer() == 0">
      <p>
        <svg-icon icon-class="empty-data" class="empty-icon" /><br />{{
          $t("board['暫無數據']")
        }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ['toBarAnswer', 'cssoption'],
  data() {
    return {
      defaultcolor: this.AnswerValuebox ? ['#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF'] : ['#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762'],
      AnswerValuebox: [],
      BarData: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cssvalue: '',
      barheight: '240',
      barwidthL: '95',
      letterOrnumber: this.$store.state.currentAnsOption == 'En' ? ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'] : ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
      reSizeMainBottom: 0,
      refreshKey: 0
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.cssvalues()
  },
  computed: {
    DynamicAnswerstatusone() {
      return this.$store.state.dynamicanswerstatus
    },
    answervalue() {
      return this.$store.state.answervalue[0]
    },
    echartbaranswer() {
      return this.$store.state.echartbaranswer
    },
    Answerstatus() {
      return this.$store.state.answerstatus
    },
    watchOptionViewHeight() {
      return this.$store.state.optionViewHeight
    }
  },
  methods: {
     checkFirstGreaterThanZeroAndRestZero(arr) {
      if (arr.length === 0) {
        return false;
      }

      if (arr[0] <= 0) {
        return false;
      }

      for (let i = 1; i < arr.length; i++) {
        if (arr[i] !== 0) {
          return false;
        }
      }

      return true;
    },
    transRightAnsNum(rightanswer) {
      if (rightanswer === 'A' || rightanswer == '1') {
        rightanswer = 0
      } else if (rightanswer === 'B' || rightanswer == '2') {
        rightanswer = 1
      } else if (rightanswer === 'C' || rightanswer == '3') {
        rightanswer = 2
      } else if (rightanswer === 'D' || rightanswer == '4') {
        rightanswer = 3
      } else if (rightanswer === 'E' || rightanswer == '5') {
        rightanswer = 4
      } else if (rightanswer === 'F' || rightanswer == '6') {
        rightanswer = 5
      } else if (rightanswer === 'G' || rightanswer == '7') {
        rightanswer = 6
      } else if (rightanswer === 'H' || rightanswer == '8') {
        rightanswer = 7
      } else if (rightanswer === 'I' || rightanswer == '9') {
        rightanswer = 8
      } else if (rightanswer === 'J' || rightanswer == '0') {
        rightanswer = 9
      }
      return rightanswer
    },
    currentOptionViewHeight() {
      return this.$store.state.optionViewHeight
    },
    isAnswer() {
      let isAnswer = this.$store.state.students.filter(item => {
        return item.option != ''
      })
      return isAnswer.length
    },
    cssvalues() {
      let echartdata = this.$store.state.echartbaranswer
      console.log(echartdata, 'echarts数据！')
      let ncolor = ['#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF']
      if (this.currentOptionViewHeight() == (window.innerHeight * 0.8).toFixed(0)) {
        this.barheight = ((window.innerHeight * 0.8).toFixed(0) - 60).toString()
        document.getElementById('main').style.height = this.barheight + 'px'
        let shopCharts = this.$echarts.init(document.getElementById('main'))
        shopCharts.resize()
      }
    },
    drawLine(defaultcolor, Data) {
      // 基于准备好的dom，初始化echarts实例

      let DataTrans = Data.map(item => item * (100 / Math.max(...Data)).toFixed(0))
      let that = this
      let myChart = this.$echarts.init(document.getElementById('main'))
      let calcheight = parseInt(this.cssoption) //parseInt(that.barheight) * (100 / Math.max(...Data)).toFixed(0) * 0.8
      that.reSizeMainBottom = calcheight - that.barheight - 30
      console.log(calcheight, this.reSizeMainBottom)
      myChart.resize({ height: calcheight })
      // 绘制图表
      myChart.setOption({
        tooltip: {
          show: false
        },
        xAxis: {
          data: this.$store.state.currentAnsOption == 'En' ? this.$store.state.AnswerSelect : this.$store.state.AnswerNumselect,
          axisLabel: {
            show: true,
            textStyle: {
              color: '#6D7278', //更改坐标轴文字颜色
              fontSize: 20 //更改坐标轴文字大小
            }
          }
        },
        yAxis: [
          {
            min: 0,
            max: 100,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: {
              formatter: function() {
                return ' '
              }
            }
          }
        ],
        series: [
          {
            name: '答案占比',
            type: 'bar',
            barWidth: 80,
            data: DataTrans,
            // silent: true,
            itemStyle: {
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [10, 10, 0, 0],
                color: function(params) {
                  var colorList = defaultcolor
                  return colorList[params.dataIndex]
                },
                label: {
                  show: true, //是否展示,
                  position: 'top',
                  formatter: function(params) {
                    let sum = 0
                    Data.forEach(item => {
                      sum = sum + item
                    })
                    if(that.checkFirstGreaterThanZeroAndRestZero(that.$store.state.echartbaranswer)){
                      return params.dataIndex==0||params.dataIndex>0&&((that.$store.state.students.length * Data[params.dataIndex]) / 100).toFixed(0)!=0? `${((that.$store.state.students.length * Data[params.dataIndex]) / 100).toFixed(0) + that.$t('board.optionView.人')}(${((Data[params.dataIndex] / sum) * 100).toFixed(0)}%)`:""
                    }
                    else{
                      return`${((that.$store.state.students.length * Data[params.dataIndex]) / 100).toFixed(0) + that.$t('board.optionView.人')}(${((Data[params.dataIndex] / sum) * 100).toFixed(0)}%)`
                    }
                   
                  },
                  textStyle: {
                    fontSize: '20',
                    fontFamily: '微软雅黑',
                    color: '#1E90FF'
                  }
                }
              }
            }
          }
        ]
      })
      //追加智慧挑人相關行為
      myChart.on('click', function(params) {
        let filterOptStudents = []
        filterOptStudents = that.$store.state.students.filter(item => item.option === params.name)
        that.$store.state.candidates_turnTable_InSmart = filterOptStudents
        that.$store.state.candidates_turnTable_InSmartOriginal = [...filterOptStudents]
        that.$store.state.turnTableInSmartAction = { PickupType: 'PickupOption', PickupOption: that.transRightAnsNum(params.name) + 1 }
        that.$parent.$parent.handleSmartPickerBtn(params.event.event.clientX?params.event.event.clientX:params.event.event.changedTouches[0].clientX, params.event.event.clientY?params.event.event.clientY:params.event.event.changedTouches[0].clientY)
        console.log(params.name, filterOptStudents, '目前點擊該選項的學生', that.$store.state.turnTableInSmartAction)
      })
    },
    init() {
      let answerpattern = this.$store.state.currentAnsOption == 'En' ? 'letter' : 'number'
      let AnswerSelect = this.$store.state.AnswerSelect
      console.log(AnswerSelect, '字母选择')
      let NumberSelect = this.$store.state.AnswerNumselect
      console.log(NumberSelect, '数字选择')
      if (answerpattern === 'letter') {
        this.letterOrnumber = AnswerSelect
      } else if (answerpattern === 'number') {
        this.letterOrnumber = NumberSelect
      }

      this.AnswerValuebox = this.$store.state.answervalue
      let color = this.defaultcolor
      if (this.AnswerValuebox) {
        let rightanswer = this.transRightAnsNum(this.AnswerValuebox[0])
        color.splice(rightanswer, 1, '#6da031')
      } else {
        this.BarData = this.$store.state.echartbaranswer
      }
      console.log(this.$store.state.answervalue, '目前答案结果')
    }
  },
  watch: {
    watchOptionViewHeight: function(value) {
      this.barheight = value
      // console.log(watchOptionViewHeight)
      let theColor = ['#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF']
      let newVal = this.answervalue
      if (newVal) {
        let rightanswer = this.transRightAnsNum(newVal)

        // console.log(rightanswer, '答案数字')
        theColor = ['#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762']
        theColor.splice(rightanswer, 1, '#6da031')
      }
      let echartsanswer = this.$store.state.echartbaranswer

      this.refreshKey++
      console.log(theColor)
      this.drawLine(theColor, echartsanswer)
    },
    echartbaranswer(a) {
      console.log(a, 'a')
      let newColor = ['#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF']

      this.drawLine(newColor, a)
    },
    BarData(b) {
      console.log(b, 'b')
      let newColor = ['#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF']

      this.drawLine(newColor, b)
    },
    answervalue: function(newVal, oldVal) {
      console.log(newVal)
      let theColor = ['#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF', '#0097FF']
      if (newVal) {
        let rightanswer = this.transRightAnsNum(newVal)

        theColor = ['#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762', '#fc5762']
        theColor.splice(rightanswer, 1, '#6da031')
      }
      let echartsanswer = this.$store.state.echartbaranswer

      this.refreshKey++
      console.log(theColor)
      this.drawLine(theColor, echartsanswer)
    },
    defaultcolor(e) {
      let answercolor = e
      let echartsanswer = this.$store.state.echartbaranswer
      this.drawLine(answercolor, echartsanswer)
    }
  }
}
</script>
<style lang="less">
#main {
  position: fixed !important;
  transition: 0.3s;
  canvas {
    transition: 0.3s;
    position: fixed;
    top: none;
    bottom: 0px;
    z-index: 999;
  }
}
</style>

<style scoped lang="less">
.topic_box {
  /*width:50%;*/
  height: 400px;
  display: block;
  float: left;
  padding: 5px;
}
.topic_title {
  font-size: 18px;
}
.topic_content {
  padding: 5px 0px;
}
.noData {
  width: 100%;
  height: auto;
  text-align: center;
  position: relative;
  top: 30px;
  // line-height: 500px;
  font-size: 22px;
  color: rgb(61, 61, 61);
  .empty-icon {
    font-size: 72px;
    padding-bottom: 5px;
  }
}
</style>
