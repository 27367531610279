<template>
  <div class="hello" :style="'width:' + barwidthL + '%;height:' + barheight + 'px;margin:0 auto;'">
    <!--<div class="topic_box">-->
    <!--<p class="topic_title">题目：</p>-->
    <!--<div class="topic_content">题目内容</div>-->
    <!--</div>-->
    <div v-show="isAnswer() != 0" id="pie" :style="'width:' + barwidthL + '%;height:' + barheight + 'px;float:left;'"></div>
    <div class="noData" v-show="isAnswer() == 0">
      <p><svg-icon icon-class="empty-data" class="empty-icon" /><br />{{ $t("board['暫無數據']") }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: ['cssoption'],
  data() {
    return {
      PieData: [],
      color: ['#00B1FF', '#FF7000', '#3DBA2D', '#FF4746', '#FFEA51', '#BE4DFF', '#FF4BFF', '#FFB4CD', '#9B8BFF', '#6AB0B8'],
      changeanswer: '',
      ProportionDatas: '',
      barheight: '240',
      barwidthL: '100',
      xz: this.$store.state.AnswerSelect,
      rightAnswerStuList: [],
      wrongAnsrStuList: []
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.cssvalues()
  },
  computed: {
    Answerstatusone() {
      return this.$store.state.answerstatus
    },
    answervalue() {
      return this.$store.state.answervalue[0]
    },
    echartbaranswer() {
      return this.$store.state.echartbaranswer
    },
    watchOptionViewHeight() {
      return this.$store.state.optionViewHeight
    }
  },
  methods: {
    transRightAnsNum(rightanswer) {
      if (rightanswer === 'A' || rightanswer == '1') {
        rightanswer = 0
      } else if (rightanswer === 'B' || rightanswer == '2') {
        rightanswer = 1
      } else if (rightanswer === 'C' || rightanswer == '3') {
        rightanswer = 2
      } else if (rightanswer === 'D' || rightanswer == '4') {
        rightanswer = 3
      } else if (rightanswer === 'E' || rightanswer == '5') {
        rightanswer = 4
      } else if (rightanswer === 'F' || rightanswer == '6') {
        rightanswer = 5
      } else if (rightanswer === 'G' || rightanswer == '7') {
        rightanswer = 6
      } else if (rightanswer === 'H' || rightanswer == '8') {
        rightanswer = 7
      } else if (rightanswer === 'I' || rightanswer == '9') {
        rightanswer = 8
      } else if (rightanswer === 'J' || rightanswer == '0') {
        rightanswer = 9
      }
      return rightanswer
    },
    isAnswer() {
      let isAnswer = this.$store.state.students.filter(item => {
        return item.option != ''
      })
      return isAnswer.length
    },
    cssvalues() {
      this.cssvalues = this.$store.state.cssvalue
      console.log(this.cssvalues, '样式值')

      this.barheight = this.cssoption
      document.getElementById('pie').style.height = this.barheight + 'px'
      let shopCharts = this.$echarts.init(document.getElementById('pie'))
      shopCharts.resize()
    },
    drawLine(resultData, color, text) {
      let that = this
      let newcolors = ['#6da031', '#fc5762', 'gray']
      let defaultColor = ['#00B1FF', '#FF7000', '#3DBA2D', '#FF4746', '#FFEA51', '#BE4DFF', '#FF4BFF', '#FFB4CD', '#9B8BFF', '#6AB0B8']
      color = this.$store.state.answervalue != '' ? newcolors : defaultColor
      document.getElementById('pie').style.height = this.cssoption + 'px'
      let MyChart_lei = this.$echarts.init(document.getElementById('pie'))
      MyChart_lei.resize()
      MyChart_lei.setOption({
        tooltip: {
          show: false,
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          selectedMode: false,
          orient: 'vertical',
          x: 'left',
          top: 40,
          left: 200,
          itemWidth: 35,
          itemHeight: 20,
          formatter: '{name}',
          textStyle: {
            color: '#000'
          },
          data: this.xz
        },
        color: color,
        calculable: true,
        series: [
          {
            name: '',
            type: 'pie',
            radius: '80%',
            center: ['50%', '50%'],
            startAngle: 315, // 起始角度
            //label: {
            //    show: true,
            //    formatter: text,
            //    fontSize: '13',
            //    padding: [0, -25],          //文字和图的边距
            //    rich: {
            //        a: {
            //            color: '#333',
            //            fontSize: 14,
            //            lineHeight: 5
            //        },
            //        b: {                        //name 文字样式
            //            fontSize: 10,
            //            lineHeight: 5,
            //            color: '#CDCDD0',
            //        },
            //        c: {                   //value 文字样式
            //            fontSize: 14,
            //            lineHeight: 5,
            //            color: '#63BF6A',
            //            align: "center"
            //        }
            //    }
            //},
            label: {
              //饼图图形上的文本标签
              normal: {
                show: true,
                position: 'inner', //标签的位置
                textStyle: {
                  fontWeight: 200,
                  fontSize: 18, //文字的字体大小
                  fontWeight: 'bolder'
                },
                formatter: function(params) {
                  console.log(params)
                  if (params.percent > 0) {
                    return params.percent.toFixed(0) + '%'
                  } else {
                    return ''
                  }
                }
              }
            },
            data: resultData
            // itemStyle: {
            //   emphasis: {
            //     shadowBlur: 20,
            //     shadowOffsetX: 0,
            //     shadowColor: 'rgba(0, 0, 0, 0.2)'
            //   },
            // //   normal: {
            // //     // 设置扇形的阴影
            // //     shadowBlur: 20,
            // //     shadowColor: 'rgba(8,78,132,0.8)',
            // //     shadowOffsetX: -3,
            // //     shadowOffsetY: 15
            // //   }
            // }
          }
        ]
      })
      //追加智慧挑人相關行為
      MyChart_lei.on('click', function(params) {
        let filterOptStudents = []
        filterOptStudents = that.$store.state.students.filter(item => item.option === params.name)
        if (params.name == '错误' && filterOptStudents.length == 0) {
          filterOptStudents = that.wrongAnsStuList
          that.$store.state.turnTableInSmartAction = { PickupType: 'PickupWrong', PickupOption: '' }
        } else if (params.name == '正确' && filterOptStudents.length == 0) {
          filterOptStudents = that.rightAnsStuList
          that.$store.state.turnTableInSmartAction = { PickupType: 'PickupRight', PickupOption: '' }
        } else {
          that.$store.state.turnTableInSmartAction = { PickupType: 'PickupOption', PickupOption: that.transRightAnsNum(params.name) + 1 }
        }

        that.$store.state.candidates_turnTable_InSmart = filterOptStudents
        that.$store.state.candidates_turnTable_InSmartOriginal = [...filterOptStudents]
        that.$parent.$parent.handleSmartPickerBtn(params.event.event.clientX?params.event.event.clientX:params.event.event.changedTouches[0].clientX, params.event.event.clientY?params.event.event.clientY:params.event.event.changedTouches[0].clientY)
        console.log(params.name, filterOptStudents, '目前點擊該選項的學生', that.$store.state.turnTableInSmartAction)
      })
    },
    init() {
      let answerpattern = this.$store.state.currentAnsOption == 'En' ? 'letter' : 'number'
      this.PieData = this.$store.state.answervalue
      console.log(this.PieData[0], '目前答案结果!!!!')
      let pieData = this.$store.state.echartbaranswer
      let answerstatus = this.$store.state.answerstatus
      let studentDats = this.$store.state.students
      console.log(studentDats, '饼图学生的情况')
      let i = ''
      let pieDatas = ''
      if (answerpattern === 'letter') {
        for (i = 0; i < pieData.length; i++) {
          pieDatas = [
            { value: pieData[0], name: 'A' },
            { value: pieData[1], name: 'B' },
            { value: pieData[2], name: 'C' },
            { value: pieData[3], name: 'D' },
            { value: pieData[4], name: 'E' },
            { value: pieData[5], name: 'F' },
            { value: pieData[6], name: 'G' },
            { value: pieData[7], name: 'H' },
            { value: pieData[8], name: 'I' },
            { value: pieData[9], name: 'J' }
          ]
        }
      } else if (answerpattern === 'number') {
        for (i = 0; i < pieData.length; i++) {
          pieDatas = [
            { value: pieData[0], name: '1' },
            { value: pieData[1], name: '2' },
            { value: pieData[2], name: '3' },
            { value: pieData[3], name: '4' },
            { value: pieData[4], name: '5' },
            { value: pieData[5], name: '6' },
            { value: pieData[6], name: '7' },
            { value: pieData[7], name: '8' },
            { value: pieData[8], name: '9' },
            { value: pieData[9], name: '0' }
          ]
        }
      }
      if (this.PieData[0]) {
        console.log('进入已设定答案的饼图')
        this.changeanswer = this.PieData[0]
        if (this.PieData[0] === '1') {
          this.changeanswer = 'A'
        } else if (this.PieData[0] === '2') {
          this.changeanswer = 'B'
        } else if (this.PieData[0] === '3') {
          this.changeanswer = 'C'
        } else if (this.PieData[0] === '4') {
          this.changeanswer = 'D'
        } else if (this.PieData[0] === '5') {
          this.changeanswer = 'E'
        } else if (this.PieData[0] === '6') {
          this.changeanswer = 'F'
        } else if (this.PieData[0] === '7') {
          this.changeanswer = 'G'
        } else if (this.PieData[0] === '8') {
          this.changeanswer = 'H'
        } else if (this.PieData[0] === '9') {
          this.changeanswer = 'J'
        }

        console.log(this.changeanswer, '重新改变的答案')

        let i = ''
        let SuccessAnswerData = []
        let ErrorAnswerData = []
        let NoAnswerData = []
        for (i = 0; i < studentDats.length; i++) {
          if (studentDats[i].option == this.changeanswer) {
            SuccessAnswerData.push(studentDats[i])
          } else if (studentDats[i].option == '') {
            NoAnswerData.push(studentDats[i])
          } else {
            ErrorAnswerData.push(studentDats[i])
          }
        }
        let SuccessProportion = Math.round((SuccessAnswerData.length / (SuccessAnswerData.length + ErrorAnswerData.length)) * 100)
        let ErrorProportion = Math.round((ErrorAnswerData.length / (SuccessAnswerData.length + ErrorAnswerData.length)) * 100)
        // let NoProportion = Math.round((NoAnswerData.length / studentDats.length) * 100)
        let ProportionData = [
          { value: SuccessProportion, name: '正确' },
          { value: ErrorProportion, name: '错误' }
          // { value: NoProportion, name: '未答' }
        ]
        this.rightAnsStuList = SuccessAnswerData
        this.wrongAnsStuList = ErrorAnswerData

        this.ProportionDatas = ProportionData
        console.log(SuccessProportion, '正确率')
        console.log(ErrorProportion, '错误')
      } else {
        console.log('进入还没设定答案的饼图')
        this.PieData = pieDatas
      }
      console.log(pieDatas, '编辑后的答案')
    }
  },
  watch: {
    watchOptionViewHeight: function(value) {
      this.init()
    },
    answervalue: function(newVal, oldVal) {
      this.init()
    },
    PieData(a) {
      console.log(a, '数据变化')
      let colors = this.color
      let text = '{b}: {d}% \n'
      this.drawLine(a, colors, text)
    },
    echartbaranswer(b) {
      this.init()
    },
    ProportionDatas(a) {
      console.log(a, '66666')
      let newtext = '{b}: {d}% \n'
      let Newscolor = this.color
      this.drawLine(a, Newscolor, newtext)
    }
  }
}
</script>

<style lang="less" scoped>
.topic_box {
  /*width:50%;*/
  height: 400px;
  display: block;
  float: left;
  padding: 5px;
}

.topic_title {
  font-size: 18px;
}

.topic_content {
  padding: 5px 0px;
}
.noData {
  width: 100%;
  height: auto;
  text-align: center;
  position: relative;
  top: 50px;
  // line-height: 500px;
  font-size: 22px;
  color: rgb(61, 61, 61);
  .empty-icon {
    font-size: 72px;
    padding-bottom: 5px;
  }
}
</style>
