<template>
  <Row class="resultbox">
    <!--设定答案-->
    <Col :class="box1">
      <div class="titleresult">
        <span>{{ $t("board.optionView['設定答案']") }}</span>
      </div>
      <!-- <div class="answerms">
          <div class="letterbtn"  @click="change('number')" :class='{"btn-on":textstatus===true}'><svg-icon icon-class="SetAns" /></div>
          <div class="letterbtn2"  @click="change('letter')" :class='{"btn-on":textstatus===false}'><svg-icon icon-class="SetAnsEn" /></div>
            
        </div> -->
      <ul class="resultnumbtn">
        <li v-for="(item, i) in numbtndata" :key="item.id" v-show="textstatus === true" @click="ClickAnswer(item.english, i, item.classname)" :class="item.classname">{{ item.english }}</li>
        <li v-for="(item, i) in numbtndata" :class="item.classname" :key="item.id" v-show="textstatus === false" @click="ClickAnswer(item.num, i, item.classname)">{{ item.num }}</li>
        <li :class="noclassName" @click="noneAnswer">{{ $t("board.optionView['無答案']") }}</li>
      </ul>
      <!--<div class="switchbox">
      <i-Switch  v-model="textstatus" @on-change="change" /><span class="icontext">数字选项</span>
    </div>-->
    </Col>
    <Col :class="box2">
      <div class="titleresult"><span>答对计分</span></div>
      <div class="pickername">
        <VuePicker :data="pickData" @cancel="cancel" @confirm="confirm" :showToolbar="false" :maskClick="false" :visible.sync="pickerVisible" @change="sswu" />
      </div>
      <div class="scorebox"><span class="scoretitle">分</span></div>
    </Col>
  </Row>
</template>
<script>
import vuePicker from 'vue-pickers'
var tdata = []
for (let i = 0; i < 20; i++) {
  tdata.push({
    label: i,
    value: i
  })
}
export default {
  components: { vuePicker },
  data() {
    return {
      box1: 'options',
      box2: 'scoringbox',
      pickerVisible: true,
      pickData: [tdata],
      result: '',
      switch1: false,
      textstatus: this.$store.state.currentAnsOption == 'En',
      numbtn: 'numbtn',
      scorecontent: '不计分',
      numbtndata: [
        { num: 1, english: 'A', classname: 'numbtn' },
        { num: 2, english: 'B', classname: 'numbtn' },
        { num: 3, english: 'C', classname: 'numbtn' },
        { num: 4, english: 'D', classname: 'numbtn' },
        { num: 5, english: 'E', classname: 'numbtn' },
        { num: 6, english: 'F', classname: 'numbtn' },
        { num: 7, english: 'G', classname: 'numbtn' },
        { num: 8, english: 'H', classname: 'numbtn' },
        { num: 9, english: 'I', classname: 'numbtn' }
      ],
      resultData: [],
      cssvalueas: this.$store.state.cssvalue,
      lettername: 'letter',
      numbername: 'number',
      noAnswer: false,
      noclassName: 'nonumbtn',
      sendMessageNow: 1
    }
  },
  computed: {
    // 获取答题状态
    AnswerStatus() {
      return this.$store.state.answerstatus
    },
    //监听cssvalue的值
    lookcssvalue() {
      return this.$store.state.cssvalue
    },
    watchnowstatus() {
      return this.$store.state.answerstatus
    }
  },
  created() {
    this.init()
  },
  mounted() {
    document.addEventListener(
      'keyup',
      e => {
        e.stopPropagation()
        if (this.$store.state.currentAnsOption == 'En') {
          this.numbtndata.forEach((element, Index) => {
            if (e.key.toUpperCase() === element.english && this.$store.state.isEditingTextNow == false) {
              this.ClickAnswer(this.numbtndata[Index].english, Index, this.numbtndata[Index].classname)
            }
          })
        } else {
          this.numbtndata.forEach((element, Index) => {
            if (parseInt(e.key) === element.num && this.$store.state.isEditingTextNow == false) {
              this.ClickAnswer(this.numbtndata[Index].num, Index, this.numbtndata[Index].classname)
            }
          })
        }
        if (e.keyCode === 32 && this.$store.state.isEditingTextNow == false) {
          this.noneAnswer()
        }
      },
      { passive: true }
    )
  },
  beforeDestroy() {
    console.log('移除EventListener')
    document.removeEventListener('keyup', e => {})
  },

  methods: {
    //初始化判定全屏还是半屏
    init() {
      let solution = this.$store.state.answervalue[0]
      //let patterns = JSON.parse(sessionStorage.getItem('msgBody'))
      // let nowpages = this.$store.state.carouselSlide
      for (var i = 0; i < this.numbtndata.length; i++) {
        if (solution === this.numbtndata[i].english || solution === this.numbtndata[i].num) {
          this.numbtndata[i].classname = 'pitchnumbtn'
        }
      }
      this.textstatus = this.$store.state.currentAnsOption == 'En'
      // if (patterns) {
      //   for (var t = 0; t < patterns.length; t++) {
      //     if (nowpages === patterns[t].page) {
      //       if (patterns[t].pattern === 'number') {
      //         this.textstatus = true
      //       } else if (patterns[t].pattern === 'letter') {
      //         this.textstatus = false
      //       }
      //     }
      //   }
      // }
    },
    // // 英文、数字设定答案切换
    //       change(status) {
    //          this.resultData = []
    //          this.textstatus = status
    //           if (status === 'number') {
    //               this.$store.state.answerpattern = 'number'
    //               this.textstatus=true
    //           } else if (status === 'letter') {
    //               this.$store.state.answerpattern = 'letter'
    //               this.textstatus=false
    //           }
    //   },
    // 设定答案选择点击
    ClickAnswer(value, numid, classname) {
      this.$emit('closeResultPop')

      //儲存mqttIoTData，CC互動有設過答案測驗就是1
      this.$store.state.mqtt.mqttIoTData.IRSType_Exam=1
      this.$store.dispatch('mqtt/saveMqttIoTsessionStorage')

      this.noclassName = 'nonumbtn'
      // 选中状态
      if (classname === 'numbtn') {
        this.numbtndata[numid].classname = 'pitchnumbtn'
        // 判断防止重复添加
        //if (this.resultData.indexOf(value, 0) === -1) {
        if (this.resultData === '' || this.resultData === null) {
          this.resultData.push(value)
        } else {
          this.resultData.splice(0, 1, value)
        }
        this.$store.state.answerstatus = 1
        // this.$store.state.IRSswitch = false
        this.$store.state.answervalue = this.resultData
        this.sendMessageNow = 0

        //}
      }
      //取消答案选项并删除数组中的数据
      if (classname === 'pitchnumbtn') {
        this.numbtndata[numid].classname = 'numbtn'
        for (var i = 0; i < this.resultData.length; i++) {
          if (this.resultData[i] === value) {
            this.resultData.splice(i, 1)
            break
          }
        }
        // // 判断没有答案了，修改设定答案状态
        if (this.resultData === '') {
          this.resultData = ['']
          this.$store.state.answerstatus = 0
        }
        // 赋值 并传递答案数组给vuex
        this.$store.state.answervalue = this.resultData
        this.sendMessageNow = 1
      }
      let answerid = numid + 1
      for (var y = 0; y < this.numbtndata.length; y++) {
        if (answerid !== this.numbtndata[y].num) {
          this.numbtndata[y].classname = 'numbtn'
        }
      }
    },
    //无答案
    noneAnswer() {
      this.$emit('closeResultPop')
      if (this.noAnswer == false) {
        this.noAnswer = true
        this.noclassName = 'clicknonumbtn'
        for (let i in this.numbtndata) {
          this.numbtndata[i].classname = 'numbtn'
        }
        this.$store.state.answerstatus = 0
        this.$store.state.answervalue = ['']
        this.sendMessageNow = 2

        // this.$store.state.IRSswitch = false
      } else {
        this.noAnswer = false
        this.noclassName = 'nonumbtn'
      }
    },
    scorechange(status) {
      if (status === true) {
        this.scorecontent = '计分'
        this.box1 = 'optionsbox'
        this.box2 = 'scoring'
        this.$emit('poptipwidth', 'ChangeWidth')
      } else {
        this.scorecontent = '不计分'
        this.box1 = 'options'
        this.box2 = 'scoringbox'
        this.$emit('poptipwidth', 'poptipStyleWidth')
      }
    },
    cancel() {
      this.result = 'click cancel result: null'
    },
    confirm(res) {
      this.result = JSON.stringify(res)
    },
    sswu(e) {
      console.log(e)
      console.log(this.pickData)
    }
  },
  watch: {
    sendMessageNow(value) {
      if (this.$store.state.showtextInuptCard==false) {
        this.$Message.success(this.$t("board.optionView['設定答案成功']"))
      } else if (value == 1) {
        this.$Message.success(this.$t("board.optionView['已移除答案']"))
      } else if (value == 2) {
        this.$Message.success(this.$t("board.optionView['設定無答案']"))
      }
    },
    watchcssvalueas(e) {
      console.log('进入监听状态')
      if (e) {
        this.cssvalueas = e
      }
    },
    //监听答题状态 改变样式
    watchnowstatus(e) {
      if (e === 0) {
        let answerdata = this.numbtndata
        for (var i = 0; i < answerdata.length; i++) {
          answerdata[i].classname = 'numbtn'
        }
      }
    }
  }
}
</script>
<style lang="less">
.pickerbox {
  width: 13vw !important;
  height: auto !important;
  position: absolute !important;
  background: inherit !important;
  top: 0px !important;
  float: left;
}
.vue-picker {
  position: static !important;
}
.content {
  height: 300px !important;
  position: static !important;
}
.colums {
  font-size: 42px !important;
}
.list ul {
  line-height: 42px !important;
}
.list li {
  padding: 0% 5% !important;
  width: 100%;
}
.ivu-poptip-popper {
  top: 40px !important;
  left: -80px !important;
}
</style>
<style scoped lang="less">
@import '../../assets/css/color.less';
.ivu-poptip-inner {
  border-radius: 15px !important;
}
.resultbox {
  width: 100%;
  height: auto !important;
}
.titleresult {
  padding: 5px;
  text-align: center;

  font-size: 16px;
  font-weight: bold;
}
.resultnumbtn {
  cursor: pointer;
  // width: 240px;
  height: auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.numbtn {
  flex: 1;
  min-width: 60px;
  max-width: 60px;
  border-radius: 50%;
  background-color: #d8d8d8;
  text-align: center;
  line-height: 60px;
  color: #000;
  font-size: 50px;
  font-weight: bold;
  margin: 5px 10px;
}
.nonumbtn {
  flex: 1;
  min-width: 100px;
  height: 40px;
  border-radius: 5%;
  background-color: #d8d8d8;
  text-align: center;
  line-height: 40px;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  margin: 20px 50px;
}
.clicknonumbtn {
  flex: 1;
  min-width: 100px;
  height: 40px;
  border-radius: 5%;
  background-color: @main-color;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin: 20px 50px;
}
.englishnumbtn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: @main-color;
  text-align: center;
  line-height: 55px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  float: left;
  margin-left: 20px;
  margin-top: 5px;
}
.pitchnumbtn {
  flex: 1;
  min-width: 60px;
  max-width: 60px;
  border-radius: 50%;
  background-color: @main-color;
  text-align: center;
  line-height: 60px;
  color: #fff;
  font-size: 50px;
  font-weight: bold;
  margin: 5px 10px;
}
.switchbox {
  width: 50%;
  padding-left: 10px;
  padding-top: 0px;
  font-size: 17px;
  color: #000;
  font-weight: bold;
  display: block;
  float: left;
}
.pickername {
  width: 70%;
  height: 300px;
  float: left;
  position: relative;
}
.scorebox {
  width: 30%;
  height: 300px;
  float: left;
  line-height: 300px;
  font-size: 42px;
}
.options {
  width: 100%;
  display: block;
}
.optionsbox {
  width: 50%;
  display: block;
  float: left;
}
.scoring {
  width: 50%;
  display: block;
  float: left;
}
.scoringbox {
  width: 0%;
  display: block;
  float: left;
  display: none;
}
.ivu-poptip-body {
  padding: 2px 16px !important;
}
.answerms {
  width: 100%;
  text-align: center;
  margin-top: -5px;
}

.numbtns {
  font-size: 10px !important;
  padding: 4px 30px !important;
}
.letterbtn {
  cursor: pointer;
  position: absolute;
  right: 10px;
  background-color: #d8d8d8;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  display: inline-block;
  font-size: 30px;
}
.letterbtn2 {
  cursor: pointer;
  position: absolute;
  top: 70px;
  right: 10px;
  background-color: #d8d8d8;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  display: inline-block;
  font-size: 30px;
}
.btn-on {
  background-color: @main-color;
  color: white;
}
</style>
