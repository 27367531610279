<template>
  <!--Flop 翻牌-->
  <div class="flop" id="flop">
    <div class="confirm-card" :style="{ bottom: `${parseInt(cssheightL) + 80}px` }" v-show="confirmCardShow">
      <p>{{ $t('board.optionView["重啟作答會清除所有已收到的數據"]') }}</p>
      <div class="confirmbtn-group">
        <div class="confirm-btn" @click="startIRS()">{{ $t('board.optionView["確定"]') }}</div>
        <div class="confirm-btn" @click="confirmCardShow = false">{{ $t('board.optionView["取消"]') }}</div>
      </div>
    </div>
    <div class="student-card" v-if="currentstuItem.sort != '' && currentstuItemShow == true" :style="{ bottom: `${this.$store.state.optionViewHeight + 80}px` }">
      <div class="student-leftimg">
        <div class="student-idnumber">{{ currentstuItem.sort }}</div>
        <div class="student-portrait"><img :src="currentstuItem.headImg" /></div>
      </div>
      <div class="student-righttext">
        <div class="student-rightname" v-if="!this.$store.state.hideStudentName">{{ currentstuItem.studentName }}</div>
        <div class="student-rightstate" v-if="currentstuItem.statement">{{ currentstuItem.statement }}</div>
        <div class="student-option" v-else>{{ currentstuItem.option }}</div>
      </div>
    </div>
    <!--智慧挑人懸停按鈕-->
    <div class="smartPicker-btn-wrap" v-show="showSmartPickerBtn" :style="`left:${smartPickerBtnPos.x}px;top:${smartPickerBtnPos.y}px`">
      <div class="smartPicker-btn" @click="handleTurnTable()"><svg-icon icon-class="choose-student" class="stroke-icon" /></div>
    </div>

    <!--初始页面-->
    <Row class="headerTollBox" v-if="fullscreen === '0'">
      <p class="title" v-if="currentIRS() == 'irsImmediately'"><svg-icon icon-class="Ques" class="stroke-icon ques-icon" />{{ $t('board.optionView["單選題"]') }}</p>
      <p class="title" v-else><svg-icon icon-class="irs-text" class="stroke-icon irstext-icon" />{{ $t('board.optionView["文字題"]') }}</p>

      <!-- <div class="triggerbox" v-if="Answerstatus === 0">
        <div :class="['allstudents', btnname === 'allstudents' ? 'pickonall' : '']" v-if="status === 'default' || status === 'list'" @click="accurate('allstudents')">全部:{{ iconnum }}</div>
        <div :class="['unanswered-btn', btnname === 'unanswered-btn' ? 'pickonaun' : '']" v-if="status === 'default' || status === 'list'" @click="accurate('unanswered-btn')">未答:{{ noanswernum }}</div>
        <div :class="['answerbtn', btnname === 'answerbtn' ? 'pickonaan' : '']" v-if="status === 'default' || status === 'list'" @click="accurate('answerbtn')">已答:{{ answernum }}</div>
      </div> -->

      <!--未设定答案之前 用户类别切换end-->
      <!--设定答案之后 用户类别切换-->

      <!-- <div class="triggerbox" v-if="Answerstatus === 1">
        <div :class="['result-allstudents', btnname === 'allstudents' ? 'pickonall' : '']" v-if="status === 'default' || status === 'list'" @click="accurate('allstudents')">全部:{{ iconnum }}</div>
        <div :class="['result-unanswered-btn', btnname === 'unanswered-btn' ? 'pickonaun' : '']" v-if="status === 'default' || status === 'list'" @click="accurate('unanswered-btn')">未答:{{ noanswernum }}</div>
        <div :class="['result-answer-error', btnname === 'answer-error' ? 'pickonerror' : '']" v-if="status === 'default' || status === 'list'" @click="accurate('answer-error')">答錯:{{ erroranswernum }}</div>
        <div :class="['result-answercorrect', btnname === 'answercorrect' ? 'pickonsuccess' : '']" v-if="status === 'default' || status === 'list'" @click="accurate('answercorrect')">答對:{{ rightanswernum }}</div>
      </div> -->
      <div class="textans-pop-bgc" v-show="currentIRS() == 'irsText' && showTextAnspop == true" @click="showTextAnspop = false"></div>
      <div class="control-btngroup" :class="[currentIRS() == 'irsImmediately' && (current == 'bar' || current == 'pie') ? 'flop-btnleft' : '']">
        <!--设定答案-->
        <Poptip :popper-class="poptipStyle" v-model="resultPopVisible" @on-popper-show="hiddenTurnTable()">
          <div :title="$t('board.optionView.設定答案')" v-show="currentIRS() == 'irsImmediately'" :class="['listicon', current === 'num' ? 'clickicon' : '']" v-if="AnswerIconstyle === 'letter'"><svg-icon icon-class="setAnsNew" /></div>
          <div :title="$t('board.optionView.設定答案')" v-show="currentIRS() == 'irsImmediately'" :class="['listicon', current === 'num' ? 'clickicon' : '']" v-if="AnswerIconstyle === 'number'"><svg-icon icon-class="setAnsNew" /></div>
          <div class="api" slot="content">
            <Result @poptipwidth="getpoptipwidth" @closeResultPop="closeResultPop" ref="ansResult" />
          </div>
        </Poptip>
        <div :title="$t('board.optionView.設定答案')" class="listicon" v-show="currentIRS() == 'irsText'" @click="openSetTextAnspop()"><svg-icon icon-class="setAnsNew" /></div>
        <!--设定答案end-->

        <div :title="$t('board.optionView.重新作答')" class="listicon resetAns" v-show="this.$store.state.IRSswitch == false || this.$store.state.currentState == '' || this.$store.state.currentState == 'irsImmediatelyEnd' || this.$store.state.currentState == 'irsTextEnd'" @click="confirmCardShow = true"><svg-icon icon-class="refresh" /></div>
        <div :title="$t('board.optionView.停止作答')" class="listicon AnswerShow" v-show="this.$store.state.IRSswitch == true && this.$store.state.currentState != '' && this.$store.state.currentState != 'irsImmediatelyEnd' && this.$store.state.currentState != 'irsTextEnd'" @click="stopIRS()"><svg-icon icon-class="stop" class="stop-icon" /></div>

        <!-- <div class="resetAns listicon" @click="againanswer" v-show="current != 'bar' && current != 'pie'"><svg-icon icon-class="refresh" /></div> -->
        <!--文字題設定答案-->
        <div class="textans-pop" v-show="currentIRS() == 'irsText' && showTextAnspop == true">
          <textarea v-model="currentAns" maxlength="200" />
          <button class="textans-btn" @click="showTextAns()">OK</button>
        </div>
        <div :title="$t('board.optionView.翻牌')" class="listicon AnswerShow" v-if="currentIRS() == 'irsText'" @click="listbtn('text')"><svg-icon icon-class="flop" /></div>
        <div :title="$t('board.optionView.文字便利貼')" class="listicon" v-if="currentIRS() == 'irsText' && status == 'text'" @click="pasteText()">
          <svg-icon :icon-class="currentSelectedTextItems != '' ? 'copyToClipboardLight' : 'copyToClipboard'" class="copyToClipboard-icon" /><span class="copyToClipboard-badge" v-if="currentSelectedTextItems.length > 0">{{ currentSelectedTextItems.length }}</span>
        </div>

        <!--button @click="listbtn('list')" :class="['listicon', current === 'list' ? 'clickicon' : '']"><svg-icon icon-class="switchover" /></button-->
        <!-- <button @click="listbtn('text')" :class='["listicon",current ==="text"? "clickicon":""]'><svg-icon icon-class="Text-icons" /></button> -->

        <div :title="$t('board.optionView.翻牌')" class="listicon AnswerShow" v-if="currentIRS() == 'irsImmediately'" @click="listbtn('default')"><svg-icon icon-class="flop" /></div>
        <div :title="$t('board.optionView.長條圖')" v-show="currentIRS() == 'irsImmediately'" @click="listbtn('bar')" :class="['listicon', current === 'bar' ? 'clickicon' : '']"><svg-icon icon-class="barchart" /></div>
        <div :title="$t('board.optionView.餅圖')" v-show="currentIRS() == 'irsImmediately'" @click="listbtn('pie')" :class="['listicon', current === 'pie' ? 'clickicon' : '']"><svg-icon icon-class="Piechart" /></div>
        <div title="gpt" class="listicon" style="line-height: 28px" v-if="currentIRS() == 'irsText' && this.$store.state.currentState == 'irsTextEnd' && isAuthOpenAIAccount()&&status=='text'" @click="triggerGPTByOpenBox()"><strong style="font-size: 12px">Gpt</strong></div>
        <div :title="$t('board.optionView.文字雲')" class="listicon" v-if="currentIRS() == 'irsText' && this.$store.state.currentState == 'irsTextEnd' && isAuthAccount() != 0 && isAuthWordCloud()" @click="triggerWordCloud()"><svg-icon icon-class="word-cloud" /></div>
        <div :title="$t('board.optionView.貼回舞台')" class="listicon" v-if="status == 'cloud' && this.$store.state.currentState == 'irsTextEnd'" @click="requestWordCloudImg()"><svg-icon icon-class="HTPaste" /></div>
        <div :title="$t('board.optionView.統計翻牌自動結束')" v-if="status !== 'cloud' && status !== 'GPT'" class="live-switch"><i-switch :title="$t('board.optionView.統計翻牌自動結束')" v-model="autoOff" /></div>
        <!--div @click="listbtn('rank')" :class='["listicon",current ==="rank"? "clickicon":""]'><svg-icon icon-class="rank" /></div-->
      </div>
      <div class="roller">
        <div class="max-trigger-area" @click="value1 = 30" />
        <div classs="sliderbox" v-if="status === 'default'"><Slider v-model="value1" :max="30" :step="10" :min="10" show-stops @on-change="valuestyle()" @on-input="valuestyle()"></Slider></div>
      </div>

      <div class="righticon-group">
        <div class="rightlisticon" :title="$t('board.optionView.匿名')" v-if="currentIRS() == 'irsText' && status !== 'cloud' && status !== 'GPT'" @click="hideName"><svg-icon :icon-class="this.$store.state.hideStudentName == false ? 'eye-show' : 'eye-hide'" /></div>
        <div class="rightlisticon" :title="$t('board.optionView.複製到剪貼簿')" v-if="currentIRS() == 'irsText' && status == 'GPT'" @click="copyGPTAnsToClipboard()"><svg-icon icon-class="copy" /></div>
        <div class="rightlisticon" :title="$t('board.optionView.複製到剪貼簿')" v-if="currentIRS() == 'irsText' && status == 'text'" @click="copyToClipboard()"><svg-icon icon-class="copy" /></div>
        <div class="rightlisticon" @click="styleadjust(aspectRatio)"><svg-icon :icon-class="aspectRatio != 'screen' ? 'full-screen' : 'exitfullscreen'" /></div>
        <div class="rightlisticon" @click="hiddenShow()"><svg-icon class="hideIRSArrow-icon" icon-class="hideIRSArrow" /></div>
      </div>
      <div>
        <ul class="studycard" v-if="status === 'default'" ref="getheight" :style="'max-height:' + cssheightL + 'px;'">
          <!--默认-->
          <li :id="index + 'A'" v-for="(item, index) in student" :key="index + 'A'" :class="item.class" v-show="item.done && status === 'default' && Answerstatus === 0 && AnswerShowhide !== 'AnswerShowOption'" :style="(item.status === 'offline' ? 'background-color:#c0c0c0;' : '') + 'width:' + widthvalue + 'px;margin-left:' + marginvalue + 'px;height:' + widthvalue + 'px;line-height:' + widthvalue + 'px'" @click="showName(item)">
            <div :class="{ 'ans-show-stuno': AnswerShowhide === 'AnswerShow' && item.option !== '' }">{{ item.sort }}</div>
            <!--div class="ans-hide-star"  v-if="AnswerShowhide === 'AnswerHide' && item.option !== ''">*</div-->
            <div class="offimg" v-if="false"><img src="../../assets/img/offlineimg.png" /></div>
            <!--作答答案-->

            <div class="answer-default-show" v-if="AnswerShowhide === 'AnswerShow' && item.option !== ''" :key="index + 'A' + forceupdatenum">
              <span>{{ item.option }}</span>
            </div>
            <div class="answer-default" v-if="item.option === ''"><span> </span></div>
            <!--作答答案end-->
          </li>
          <!--设定答案后的默认-->
          <li v-for="(item, index) in student" :key="index + 'B'" :class="item.class" v-show="item.done && status === 'default' && Answerstatus === 1 && AnswerShowhide === 'AnswerHide'" :style="(item.status === 'offline' ? 'background-color:#c0c0c0;' : '') + 'width:' + widthvalue + 'px;margin-left:' + marginvalue + 'px;height:' + widthvalue + 'px;line-height:' + widthvalue + 'px'" @click="showName(item)">
            <div :class="{ 'ans-show-stuno': AnswerShowhide === 'AnswerShow' && item.option !== '' }">{{ item.sort }}</div>
            <!--div class="ans-hide-star"  v-if="AnswerShowhide === 'AnswerHide' && item.option !== ''">*</div-->
            <div class="offimg" v-if="false"><img src="../../assets/img/offlineimg.png" /></div>
            <!--作答答案-->
            <div class="answer-default" v-if="item.option === ''"><span> </span></div>
            <!--作答答案end-->
          </li>
          <li v-for="(item, index) in student" :key="index + 'C'" :class="item.answerclass" v-show="item.done && status === 'default' && Answerstatus === 1 && AnswerShowhide === 'AnswerShow'" :style="(item.status === 'offline' ? 'background-color:#c0c0c0;' : '') + 'width:' + widthvalue + 'px;margin-left:' + marginvalue + 'px;height:' + widthvalue + 'px;line-height:' + widthvalue + 'px'" @click="showName(item)">
            <div :class="{ 'ans-show-stuno': AnswerShowhide === 'AnswerShow' && item.option !== '' }">{{ item.sort }}</div>

            <div class="offimg" v-if="false"><img src="../../assets/img/offlineimg.png" /></div>
            <!--作答答案 设定答案后-->
            <div class="answer-default-show" v-if="AnswerShowhide === 'AnswerShow' && item.option !== ''">
              <span>{{ item.option }}</span>
            </div>
            <div class="answer-default" v-if="item.option === ''"><span> </span></div>
            <!--作答答案 设定答案后end-->
            <!--发言内容-->
            <div class="q-pa-md">
              <q-btn push class="statement-icon11" @click="angleshow()" v-if="item.statement !== ''">
                <q-popup-proxy @before-hide="hideangleicon()" :offset="[65, -165]">
                  <draggable class="list-group" @start="statementToimage" @end="stickupend">
                    <div class="pop-up" id="statementBox" @click="detailbtn($event)">
                      <p>{{ item.statement }}</p>
                      <div class="statementInfo">{{ item.sort }}{{ item.studentName }}</div>
                    </div>
                  </draggable>
                </q-popup-proxy>
              </q-btn>
            </div>
            <q-dialog v-model="alert" transition-show="rotate" transition-hide="rotate">
              <q-card>
                <q-card-section class="q-pt-none">
                  {{ detailContent }}
                  <div class="detailbox">
                    <div class="fr">
                      <span>来自于：{{ deatilName }}</span>
                    </div>
                    <!--<div class="fr"><span>时间111：</span></div>-->
                  </div>
                </q-card-section>
              </q-card>
            </q-dialog>
            <!--发言内容end-->
          </li>

          <!--分選項檢視-->
          <div class="option-view" v-if="status === 'default' && AnswerShowhide === 'AnswerShowOption'">
            <div v-if="studentOptionLength('A') != 0 || studentOptionLength('1') != 0">
              <p>{{ pattern == "letter" ? "A" : "1" }}</p>
              <li v-for="(item, index) in student" :key="index + 'opA'" :class="Answerstatus === 1 ? item.answerclass : 'studyboxone'" v-show="item.option == 'A' || item.option == '1'" @click="showName(item)" :style="'width:' + widthvalue + 'px;margin-left:' + marginvalue + 'px;height:' + widthvalue + 'px;line-height:' + widthvalue + 'px;font-size' + fontSizeValue + 'px'">{{ item.sort }}</li>
            </div>
            <div v-if="studentOptionLength('B') != 0 || studentOptionLength('2') != 0">
              <p>{{ pattern == "letter" ? "B" : "2" }}</p>
              <li v-for="(item, index) in student" :key="index + 'opB'" :class="Answerstatus === 1 ? item.answerclass : 'studyboxone'" v-show="item.option == 'B' || item.option == '2'" @click="showName(item)" :style="'width:' + widthvalue + 'px;margin-left:' + marginvalue + 'px;height:' + widthvalue + 'px;line-height:' + widthvalue + 'px;font-size' + fontSizeValue + 'px'">{{ item.sort }}</li>
            </div>
            <div v-if="studentOptionLength('C') != 0 || studentOptionLength('3') != 0">
              <p>{{ pattern == "letter" ? "C" : "3" }}</p>
              <li v-for="(item, index) in student" :key="index + 'opC'" :class="Answerstatus === 1 ? item.answerclass : 'studyboxone'" v-show="item.option == 'C' || item.option == '3'" @click="showName(item)" :style="'width:' + widthvalue + 'px;margin-left:' + marginvalue + 'px;height:' + widthvalue + 'px;line-height:' + widthvalue + 'px;font-size' + fontSizeValue + 'px'">{{ item.sort }}</li>
            </div>
            <div v-if="studentOptionLength('D') != 0 || studentOptionLength('4') != 0">
              <p>{{ pattern == "letter" ? "D" : "4" }}</p>
              <li v-for="(item, index) in student" :key="index + 'opD'" :class="Answerstatus === 1 ? item.answerclass : 'studyboxone'" v-show="item.option == 'D' || item.option == '4'" @click="showName(item)" :style="'width:' + widthvalue + 'px;margin-left:' + marginvalue + 'px;height:' + widthvalue + 'px;line-height:' + widthvalue + 'px;font-size' + fontSizeValue + 'px'">{{ item.sort }}</li>
            </div>
            <div v-if="studentOptionLength('E') != 0 || studentOptionLength('5') != 0">
              <p>{{ pattern == "letter" ? "E" : "5" }}</p>
              <li v-for="(item, index) in student" :key="index + 'opE'" :class="Answerstatus === 1 ? item.answerclass : 'studyboxone'" v-show="item.option == 'E' || item.option == '5'" @click="showName(item)" :style="'width:' + widthvalue + 'px;margin-left:' + marginvalue + 'px;height:' + widthvalue + 'px;line-height:' + widthvalue + 'px;font-size' + fontSizeValue + 'px'">{{ item.sort }}</li>
            </div>
            <div v-if="studentOptionLength('F') != 0 || studentOptionLength('6') != 0">
              <p>{{ pattern == "letter" ? "F" : "6" }}</p>
              <li v-for="(item, index) in student" :key="index + 'opF'" :class="Answerstatus === 1 ? item.answerclass : 'studyboxone'" v-show="item.option == 'F' || item.option == '6'" @click="showName(item)" :style="'width:' + widthvalue + 'px;margin-left:' + marginvalue + 'px;height:' + widthvalue + 'px;line-height:' + widthvalue + 'px;font-size' + fontSizeValue + 'px'">{{ item.sort }}</li>
            </div>
            <div v-if="studentOptionLength('G') != 0 || studentOptionLength('7') != 0">
              <p>{{ pattern == "letter" ? "G" : "7" }}</p>
              <li v-for="(item, index) in student" :key="index + 'opF'" :class="Answerstatus === 1 ? item.answerclass : 'studyboxone'" v-show="item.option == 'G' || item.option == '7'" @click="showName(item)" :style="'width:' + widthvalue + 'px;margin-left:' + marginvalue + 'px;height:' + widthvalue + 'px;line-height:' + widthvalue + 'px;font-size' + fontSizeValue + 'px'">{{ item.sort }}</li>
            </div>
            <div v-if="studentOptionLength('H') != 0 || studentOptionLength('8') != 0">
              <p>{{ pattern == "letter" ? "H" : "8" }}</p>
              <li v-for="(item, index) in student" :key="index + 'opF'" :class="Answerstatus === 1 ? item.answerclass : 'studyboxone'" v-show="item.option == 'H' || item.option == '8'" @click="showName(item)" :style="'width:' + widthvalue + 'px;margin-left:' + marginvalue + 'px;height:' + widthvalue + 'px;line-height:' + widthvalue + 'px;font-size' + fontSizeValue + 'px'">{{ item.sort }}</li>
            </div>
            <div v-if="studentOptionLength('I') != 0 || studentOptionLength('9') != 0">
              <p>{{ pattern == "letter" ? "I" : "9" }}</p>
              <li v-for="(item, index) in student" :key="index + 'opF'" :class="Answerstatus === 1 ? item.answerclass : 'studyboxone'" v-show="item.option == 'I' || item.option == '9'" @click="showName(item)" :style="'width:' + widthvalue + 'px;margin-left:' + marginvalue + 'px;height:' + widthvalue + 'px;line-height:' + widthvalue + 'px;font-size' + fontSizeValue + 'px'">{{ item.sort }}</li>
            </div>
            <div v-if="studentOptionLength('') != 0">
              <p>{{ $t('board.optionView["未作答"]') }}</p>
              <li v-for="(item, index) in student" :key="index + 'opG'" :class="Answerstatus === 1 ? item.answerclass : 'studyboxone'" v-show="item.option == ''" @click="showName(item)" :style="'width:' + widthvalue + 'px;margin-left:' + marginvalue + 'px;height:' + widthvalue + 'px;line-height:' + widthvalue + 'px;font-size' + fontSizeValue + 'px'">{{ item.sort }}</li>
            </div>
          </div>
          <!--分選項檢視-->
        </ul>
        <!--名牌列表-->
        <ul class="vice-studyshowcard" v-if="status === 'list'" :style="'height:' + cssheightL + 'px;'">
          <!--未设置答案前-->
          <li :class="item.showcardclass" v-for="(item, index) in student" :key="index + 'D'" v-show="item.done && status === 'list' && Answerstatus === 0">
            <div class="leftimg">
              <div class="idnumber">{{ item.sort }}</div>
              <div class="portrait"><img :src="item.headImg" /></div>
              <div class="mpoff" v-if="item.status === 'offline'"><img src="../../assets/img/offlineimg.png" /></div>
            </div>
            <div class="righttext">
              <div class="showhide-answer" v-if="AnswerShowhide === 'AnswerHide' && item.option !== ''">*</div>
              <div class="showhide-answer" v-if="AnswerShowhide === 'AnswerShow' && item.option !== ''">{{ item.option }}</div>
              <div class="showhide-answer" v-if="item.option === ''"></div>
              <!--名牌发言-->
              <div class="q-pa-md">
                <p class="statement">{{ item.statement }}</p>
                <!-- <q-btn push class="namebrand-pop" @click="angleshow()" v-if="item.statement !== ''">
                  <q-popup-proxy @before-hide="hideangleicon()" :offset="[90, -155]">
                    <draggable class="list-group" @start="statementToimage" @end="stickupend">
                      <div class="pop-up" id="statementBox" @click="detailbtn($event)">
                        
                        <div class="statementInfo">{{ item.sort }}{{ item.studentName }}</div>
                      </div>
                    </draggable>
                  </q-popup-proxy>
                </q-btn> -->
              </div>
              <q-dialog v-model="alert" transition-show="rotate" transition-hide="rotate">
                <q-card>
                  <q-card-section class="q-pt-none">
                    {{ detailContent }}
                    <div class="detailbox">
                      <div class="fr">
                        <span>来自于：{{ deatilName }}</span>
                      </div>
                      <!--<div class="fr"><span>时间111：</span></div>-->
                    </div>
                  </q-card-section>
                </q-card>
              </q-dialog>
              <!--名牌发言end-->
              <div class="AnswerStudentname">{{ item.studentName }}</div>
            </div>
          </li>
          <!--未设置答案前end-->
          <!--设置答案后  答案隐藏-->
          <li :class="item.showcardclass" v-for="(item, index) in student" :key="index + 'E'" v-show="item.done && status === 'list' && Answerstatus === 1 && AnswerShowhide === 'AnswerHide'">
            <div class="leftimg">
              <div class="idnumber">{{ item.sort }}</div>
              <div class="portrait"><img :src="item.headImg" /></div>
              <div class="mpoff" v-if="item.status === 'offline'"><img src="../../assets/img/offlineimg.png" /></div>
            </div>
            <div class="righttext">
              <div class="showhide-answer" v-if="AnswerShowhide === 'AnswerHide' && item.option !== ''">*</div>
              <div class="showhide-answer" v-if="AnswerShowhide === 'AnswerShow' && item.option !== ''">{{ item.option }}</div>
              <div class="showhide-answer" v-if="item.option === ''"></div>
              <!--名牌发言-->
              <div class="q-pa-md">
                <q-btn push class="namebrand-pop" @click="angleshow()" v-if="item.statement !== ''">
                  <q-popup-proxy @before-hide="hideangleicon()" :offset="[90, -155]">
                    <draggable class="list-group" @start="statementToimage" @end="stickupend">
                      <div class="pop-up" id="statementBox" @click="detailbtn($event)">
                        <p>{{ item.statement }}</p>
                        <div class="statementInfo">{{ item.sort }}{{ item.studentName }}</div>
                      </div>
                    </draggable>
                  </q-popup-proxy>
                </q-btn>
              </div>
              <q-dialog v-model="alert" transition-show="rotate" transition-hide="rotate">
                <q-card>
                  <q-card-section class="q-pt-none">
                    {{ detailContent }}
                    <div class="detailbox">
                      <div class="fr">
                        <span>来自于：{{ deatilName }}</span>
                      </div>
                      <!--<div class="fr"><span>时间111：</span></div>-->
                    </div>
                  </q-card-section>
                </q-card>
              </q-dialog>
              <!--名牌发言end-->
              <div class="AnswerStudentname">{{ item.studentName }}</div>
            </div>
          </li>
          <!--设置答案后  答案隐藏end-->
          <!--设置答案后  答案显示-->
          <li :class="item.answerbrand" v-for="item in student" :key="item.id" v-show="item.done && status === 'list' && Answerstatus == 1 && AnswerShowhide === 'AnswerShow'">
            <div class="leftimg">
              <div class="idnumber">{{ item.sort }}</div>
              <div class="portrait"><img :src="item.headImg" /></div>
              <div class="mpoff" v-if="item.status === 'offline'"><img src="../../assets/img/offlineimg.png" /></div>
            </div>
            <div class="answer-righttext">
              <div class="showhide-answer" v-if="AnswerShowhide === 'AnswerHide' && item.option !== ''">*</div>
              <div class="showhide-answer" v-if="AnswerShowhide === 'AnswerShow' && item.option !== ''">{{ item.option }}</div>
              <div class="showhide-answer" v-if="item.option === ''"></div>
              <!--名牌发言 设定答案后-->
              <div class="q-pa-md">
                <q-btn push class="namebrand-pops" @click="angleshow()" v-if="item.statement !== ''"
                  >!
                  <q-popup-proxy @before-hide="hideangleicon()" :offset="[80, -155]">
                    <draggable class="list-group" @start="statementToimage" @end="stickupend">
                      <div class="pop-up" id="statementBox" @click="detailbtn($event)">
                        <p>{{ item.statement }}</p>
                        <div class="statementInfo">{{ item.sort }}{{ item.studentName }}</div>
                      </div>
                    </draggable>
                  </q-popup-proxy>
                </q-btn>
              </div>
              <q-dialog v-model="alert" transition-show="rotate" transition-hide="rotate">
                <q-card>
                  <q-card-section class="q-pt-none">
                    {{ detailContent }}
                    <div class="detailbox">
                      <div class="fr">
                        <span>来自于：{{ deatilName }}</span>
                      </div>
                      <!--<div class="fr"><span>时间111：</span></div>-->
                    </div>
                  </q-card-section>
                </q-card>
              </q-dialog>
              <!--名牌发言 设定答案后end-->
              <p style="color: #fff">{{ item.studentName }}</p>
            </div>
          </li>
          <!--设置答案后end-->
        </ul>
        <!--文字模式收集-->
        <ul class="textblock-wrap" v-if="status === 'text'" :style="'max-height:' + cssheightL + 'px;'" :class="{ 'textblock-wrapblank': student.length % 3 != 0 }">
          <li v-for="(item, index) in student" :key="index + 'opText'" class="text-block" v-show="item.option == ''" @click="selectShowName(item)" :class="{ 'text-answer': item.statement != '', 'text-anscorrect': item.statement == currentTextAns() && Answerstatus === 1, 'text-answrong': item.statement != currentTextAns() && Answerstatus === 1, 'text-selected': currentSelectedTextItems.includes(item) }">
            <div class="student-no">{{ item.sort }}</div>
            <p class="student-state">{{ item.statement }}</p>
          </li>
        </ul>
        <!--文字模式收集end-->
        <!--柱状图-->
        <ul class="vice-studyshowcard" v-if="status === 'bar'" :style="'height:' + cssheightL + 'px;'">
          <div v-if="status === 'bar'">
            <Bar :toBarAnswer="settinganswer" :cssoption="cssheightL"></Bar>
          </div>
        </ul>
        <!--饼图-->
        <ul class="vice-studyshowcard" v-if="status === 'pie'" :style="'height:' + cssheightL + 'px;'">
          <div v-if="status === 'pie'">
            <Pie :cssoption="cssheightL"></Pie>
          </div>
        </ul>
        <!--排行榜-->
        <ul class="vice-studyshowcard" v-if="status === 'rank'" :style="'height:' + cssheightL + 'px;'">
          <li class="brand-rank" v-for="item in RankDatas" :key="item.id" v-show="item.done && status === 'rank'">
            <div class="leftimg">
              <div class="idnumber">{{ item.sort }}</div>
              <div class="portrait"><img :src="item.headImg" /></div>
            </div>
            <div class="rankright">
              <div class="trophy" v-if="item.rank === '1'"><img src="@/statics/iconsvg/interaction/TrophyOne.svg" /></div>
              <div class="trophy" v-if="item.rank === '2'"><img src="@/statics/iconsvg/interaction/TrophyTwo.svg" /></div>
              <div class="trophy" v-if="item.rank === '3'"><img src="@/statics/iconsvg/interaction/TrophyThree.svg" /></div>
              <div class="snbox">
                <p class="sn-num">{{ item.score }}</p>
                <p class="sn-name">{{ item.studentName }}</p>
              </div>
            </div>
          </li>
        </ul>
        <!-- 文字雲詞頻表格
        <div id="wordcloudFreqTable" v-show="status === 'cloud' && this.$store.state.currentState == 'irsTextEnd' && wordCloudFreq.length > 0" :style="'height:' + (Number(cssheightL) - 50) + 'px;'">
          <div v-for="item in wordCloudFreq" :key="item.name" @click="showWordCloudTableSmartPickBtn(item, $event)" class="word-item" >
            <span>{{ item.value }}&nbsp;&nbsp;{{ item.name }}</span>
          </div>
        </div> -->
        <!--文字雲-->
        <iframe scrolling="no" v-show="status === 'cloud' && this.$store.state.currentState == 'irsTextEnd'" id="wordcloudiframe" :src="hiteachccorigin + wordcloudDic + 'index.html'" :style="'height:' + cssheightL + 'px'" />
        <!-- <div v-show="status === 'GPT'" id="IRSChatGptSelect">
          <Select v-model="currentSelectedGptAct" :placement="'top'" :transfer="true" @on-select="triggerGPTBySelect()">
            <Option v-for="(opt, index) in gptActionList" :value="opt.value" :key="index + opt.value">{{ opt.value }}</Option>
          </Select>
        </div> -->
        <!-- <div id="IRSChatGptBox" v-show="status === 'GPT' && this.$store.state.currentState == 'irsTextEnd'" :style="'max-height:' + (Number(cssheightL) - 80) + 'px'"></div> -->
      </div>
    </Row>
  </div>
</template>
<script>
import Bar from "./../Chart/Bar.vue";
import Pie from "./../Chart/Pie.vue";
import Result from "./../Chart/result.vue";
import draggable from "vuedraggable";
import html2canvas from "html2canvas";
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
  components: {
    Bar,
    Pie,
    Result,
    draggable,
  },

  data() {
    return {
      hiteachccorigin: "",
      showSmartPickerBtn: false,
      smartPickerBtnPos: { x: 0, y: 0 },
      smartPickerBtnTimeout: "",
      resultPopVisible: false,
      visible: false,
      show: true,
      current: "",
      dwidthOld: 1000,
      poptipStyle: "poptipStyleWidth",
      selection: [],
      status: "default",
      iconclass: "allstudents",
      icontext: "全部人员",
      iconnum: "0",
      numClickIcon: "listicon",
      idlernum: 15,
      Answerstatus: this.$store.state.answerstatus,
      pattern: this.$store.state.currentAnsOption == "En" ? "letter" : "number",
      //studentList: [
      //    { 'id': 1, 'name': '王明', 'option': 'A', done: true, 'score': '89', rank: '1', class: 'studybox', showcardclass: 'brand-answer', answerclass: 'answer-error', answerbrand: 'brand', imgUrl: require('../../assets/img/img01.jpg') },
      //    { 'id': 2, 'name': '陈曦', 'option': '', done: true, 'score': '80', rank: '2', class: 'studyboxone', showcardclass: 'brand', answerclass: 'studyboxone', answerbrand: 'brand', imgUrl: require('../../assets/img/img02.jpg') },
      //    { 'id': 3, 'name': '王涵', 'option': '', done: true, 'score': '72', rank: '3', class: 'studyboxone', showcardclass: 'brand', answerclass: 'studyboxone', answerbrand: 'brand', imgUrl: require('../../assets/img/img03.jpg') },
      //    { 'id': 4, 'name': '王明', 'option': 'B', done: true, 'score': '79', rank: '4', class: 'studybox', showcardclass: 'brand-answer', answerclass: 'answercorrect', answerbrand: 'brand', imgUrl: require('../../assets/img/img04.jpg') },
      //    { 'id': 5, 'name': '陈曦', 'option': '', done: true, 'score': '69', rank: '5', class: 'studyboxone', showcardclass: 'brand', answerclass: 'studyboxone', answerbrand: 'brand', imgUrl: require('../../assets/img/img05.jpg') },
      //    { 'id': 6, 'name': '陈曦', 'option': 'B', done: true, 'score': '68', rank: '6', class: 'studybox', showcardclass: 'brand-answer', answerclass: 'answercorrect', answerbrand: 'brand', imgUrl: require('../../assets/img/img01.jpg') },
      //    { 'id': 7, 'name': '王明', 'option': '', done: true, 'score': '95', rank: '7', class: 'studyboxone', showcardclass: 'brand', answerclass: 'studyboxone', answerbrand: 'brand', imgUrl: require('../../assets/img/img02.jpg') },
      //    { 'id': 8, 'name': '李毅', 'option': '', done: true, 'score': '99', rank: '8', class: 'studyboxone', showcardclass: 'brand', answerclass: 'studyboxone', answerbrand: 'brand', imgUrl: require('../../assets/img/img03.jpg') },
      //    { 'id': 9, 'name': '范志毅', 'option': '', done: true, 'score': '69', rank: '9', class: 'studyboxone', showcardclass: 'brand', answerclass: 'studyboxone', answerbrand: 'brand', imgUrl: require('../../assets/img/img04.jpg') },
      //    { 'id': 10, 'name': '李毅于', 'option': 'C', done: true, 'score': '59', rank: '10', class: 'studybox', showcardclass: 'brand-answer', answerclass: 'answer-error', answerbrand: 'brand', imgUrl: require('../../assets/img/img05.jpg') },
      //    { 'id': 11, 'name': '王治郅', 'option': '', done: true, 'score': '75', rank: '11', class: 'studyboxone', showcardclass: 'brand', answerclass: 'studyboxone', answerbrand: 'brand', imgUrl: require('../../assets/img/img01.jpg') },
      //    { 'id': 12, 'name': '王龙军', 'option': 'C', done: true, 'score': '79', rank: '12', class: 'studybox', showcardclass: 'brand-answer', answerclass: 'answer-error', answerbrand: 'brand', imgUrl: require('../../assets/img/img02.jpg') },
      //    { 'id': 13, 'name': '张明', 'option': '', done: true, 'score': '83', rank: '13', class: 'studyboxone', showcardclass: 'brand', answerclass: 'studyboxone', answerbrand: 'brand', imgUrl: require('../../assets/img/img03.jpg') },
      //    { 'id': 14, 'name': '王狗子', 'option': '', done: true, 'score': '80', rank: '14', class: 'studyboxone', showcardclass: 'brand', answerclass: 'studyboxone', answerbrand: 'brand', imgUrl: require('../../assets/img/img04.jpg') },
      //    { 'id': 15, 'name': '张三', 'option': 'A', done: true, 'score': '90', rank: '15', class: 'studybox', showcardclass: 'brand-answer', answerclass: 'answer-error', answerbrand: 'brand', imgUrl: require('../../assets/img/img05.jpg') }
      //],
      student: this.$store.state.students,
      changeList: ["status"],
      value1: 20,
      windowWidth: window.innerWidth,
      value1Max: Number(30),
      unansweredbtn: "unanswered-btn",
      answerbtn: "answerbtn",
      answersuccess: "correct-btn",
      answererror: "error-btn",
      hiddenbtn: "",
      NotanswerStatus: "",
      screenunanswered: "unanswered-screen",
      screenanswer: "screen-answer",
      aspectRatio: "default",
      fullscreen: "0",
      styleObjDefault: {
        isShow: true,
        width: "100%",
        height: "300px",
        maxWidth: "1920px",
        marginLeft: "0px",
        marginTop: "0px",
        backgroundColor: "#EDEDED",
        componentIndex: 1,
        isShowCard: true,
      },
      styleObjScreen: {
        isShow: true,
        width: "100%",
        height: `${window.innerHeight * 0.8}px`,
        maxWidth: "1920px",
        marginLeft: "0px",
        marginTop: "0px",
        backgroundColor: "#EDEDED",
        componentIndex: 1,
        isShowCard: true,
      },
      settinganswer: "",
      answernum: "",
      noanswernum: "",
      rightanswernum: this.$store.state.rightanswernum,
      erroranswernum: this.$store.state.erroranswernum,
      /*名牌显示答案icon图表*/
      ShowHideanswer: require("../../assets/img/hide-answer.png"),
      AnswerShowhide: "AnswerHide",
      /*阈值处理宽度样式*/

      widthvalue: "",
      marginvalue: "",
      fontSizeValue: "",
      /*设定答案icon value*/
      AnswerIconstyle: "letter",
      /*poptip*/
      angleicon: false,
      poptipbox: false,
      /*当前状态值*/
      btnname: "allstudents",
      //关联CSSvalue
      cssvalue: "0",
      cssheightL: "240",
      ulheight: "215",
      //排行榜
      RankDatas: [],
      //IRS开关
      irsswitch: true,
      confirm: false,
      //便利贴
      isDragging: false,
      alert: false,
      irsBack: this.$store.state.irsBack,
      studentToimageurl: "",
      //便利贴查看详情
      deatilName: "",
      detailContent: "",
      currentstuItem: {
        sort: "",
        headImg: "",
        studentName: "",
      },
      currentstuItemShow: false,
      currentAns: "",
      showTextAnspop: false,
      currentSelectedTextItems: [],
      forceupdatenum: 0,
      autoOff: true,
      confirmCardShow: false,
      pasteTextTime: 0,
      wordCloudFreq: [],
      currentSelectedGptAct: "",
      boardElement: this.$parent.$parent.$parent,
    };
  },

  created() {
    this.studentss();
  },
  mounted() {
    Vue.prototype.$optionView = this;

    let that = this;
    this.hiteachccorigin = window.location.origin;
    this.$nextTick(() => {
      //在optionview中文字雲的監聽邏輯
      window.onmessage = function (e) {
        if (e.origin !== that.hiteachccorigin) return;
        console.log(e.data);

        if (e.data?.action == "WordCloud_Ack") {
          e.data?.countFreq ? (that.wordCloudFreq = e.data.countFreq) : (that.wordCloudFreq = []);
          that.$q.loading.hide();
        } else if (e.data?.action == "WordCloud_Img") {
          that.pasteWordCloudImg(e);
        } else if (e.data?.word) {
          let offserY = that.aspectRatio != "default" ? window.innerHeight * 0.1 : window.innerHeight * 0.5;
          let clientEvent = {
            clientX: e.data.x,
            clientY: e.data.y + offserY,
          };
          let wordItemIndex = that.wordCloudFreq.findIndex((item) => item.name == e.data.word);
          that.$q.loading.hide();
          that.showWordCloudTableSmartPickBtn(that.wordCloudFreq[wordItemIndex], clientEvent);
        } 
      };

      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
      if (this.$store.state.alreadySetIRSBlockSize == false) {
        this.value1 = parseInt(this.getPREFIRSBlockSize);
        this.$store.state.alreadySetIRSBlockSize = true;
      } else {
        this.value1 = parseInt(this.$store.state.tempIRSBlockSize);
      }
      this.valuestyle();
    });

    //mockstudents
    // let mockstudents = []
    // for (let i = 1; i <= 100; i++) {
    //   mockstudents.push({
    //     sort: i,
    //     id: '711545724451'+i,
    //     accountid: '1637115457243'+i,
    //     studentName: 'louise'+i,
    //     sendTime: 1637115468066,
    //     headImg: 'https://img.zcool.cn/community/01786557e4a6fa0000018c1bf080ca.png@1280w_1l_2o_100sh.png',
    //     option: 'B',
    //     done: true,
    //     score: 0,
    //     rank: '',
    //     class: 'studyboxone',
    //     showcardclass: 'brand',
    //     answerclass: 'studyboxone',
    //     answerbrand: 'brand',
    //     statement: '',
    //     status: 'online'
    //   })
    // }
    // this.$store.state.students=mockstudents
  },
  computed: {
    ...mapGetters({
      getPREFIRSBlockSize: "preferences/getIRSBlockSize", // 取得IRS Block 大小
      classInfo: "classInfo/getInfo", // 取得課堂設定
    }),
    gptActionList() {
      return this.$store.state.tempGptActionList;
    },
    watchStudents() {
      return this.$store.state.students;
    },
    Answerstatusone() {
      return this.$store.state.answerstatus;
    },
    watchAnswervalue() {
      return this.$store.state.answervalue;
    },
    watchAnswernum() {
      return this.$store.state.answernum;
    },
    watchNoAnswernum() {
      return this.$store.state.noanswernum;
    },
    watchRightAnswernum() {
      return this.$store.state.rightanswernum;
    },
    watchErrorAnswernum() {
      return this.$store.state.erroranswernum;
    },
    watchAnswerpattern() {
      return this.$store.state.answerpattern;
    },
    watchOptionViewHeight() {
      return this.$store.state.optionViewHeight;
    },
    lang: function () {
      switch (this.$i18n.locale) {
        case "zh-TW":
          return "tw";
        case "zh-CN":
          return "cn";
        case "en-US":
          return "en";
      }
    },
    wordcloudDic() {
      return this.lang != "cn" ? "/wordcloud/" : "/wordcloudcn/";
    },
  },
  methods: {
    isAuthOpenAIAccount() { //改介接OpenAI的權益
      return this.$parent.$parent.$parent.isAuthOpenAIAccount();
    },
    isAuthWordCloud() {
      return this.$parent.$parent.$parent.isAuthWordCloud();
    },
    isAuthOnlyPureScan(){
      return this.$parent.$parent.$parent.isAuthOnlyPureScan()
    },
    isAuthAccount() {
      return this.$parent.$parent.$parent.isAuthAccount();
    },
    hiddenTurnTable() {
      let toolIndexList = this.$parent.$parent.$parent.toolIndexList;
      toolIndexList.splice(toolIndexList.indexOf(1), 1);
    },
    handleTurnTable() {
      this.$store.state.turnTableＭode = "Smart";
      //把外層的挑人打開
      if (!this.$parent.$parent.$parent.toolIndexList.includes(1)) {
        this.$parent.$parent.$parent.toolIndexList.push(1);
      }
      this.showSmartPickerBtn = false;
      this.smartPickerBtnTimeout = "";
      this.smartPickerBtnTimeout = setTimeout(() => {
        //等候才抓得到
        this.$parent.$parent.$parent.$refs.TurnTable.pickOne();
      }, 300);
    },
    handleSmartPickerBtn(x, y) {
      this.showSmartPickerBtn = true;
      this.smartPickerBtnPos.x = x;
      this.smartPickerBtnPos.y = y;
      clearTimeout(this.smartPickerBtnTimeout);
      this.smartPickerBtnTimeout = "";
      this.smartPickerBtnTimeout = setTimeout(() => {
        this.showSmartPickerBtn = false;
      }, 5000);
    },
    closeResultPop() {
      this.resultPopVisible = false;
    },
    hideName() {
      this.$store.state.hideStudentName = !this.$store.state.hideStudentName;
    },
    copyGPTAnsToClipboard() {
      // Create an auxiliary hidden input
      var aux = document.createElement("textarea");

      let alltext = "";
      document.querySelectorAll("#gpt-answer").forEach((item) => {
        console.log(item);
        alltext = alltext + item.innerText + "\t\n";
      });
      aux.value = alltext;

      // Append the aux input to the body
      document.body.appendChild(aux);

      // Highlight the content
      aux.select();

      // Execute the copy command
      document.execCommand("copy");

      // Remove the input from the body
      document.body.removeChild(aux);

      this.$Message.info(this.$t("board.optionView['已全部複製到剪貼簿!']"));
    },
    copyToClipboard() {
      let studentStatement = "";
      this.student.forEach((item) => {
        studentStatement = studentStatement + "[" + item.sort + "]" + item.studentName + ":\t" + item.statement + "\n";
      });

      // Create an auxiliary hidden input
      var aux = document.createElement("textarea");

      // Get the text from the element passed into the input
      aux.value = studentStatement;

      // Append the aux input to the body
      document.body.appendChild(aux);

      // Highlight the content
      aux.select();

      // Execute the copy command
      document.execCommand("copy");

      // Remove the input from the body
      document.body.removeChild(aux);

      this.$Message.info(this.$t("board.optionView['已全部複製到剪貼簿!']"));
    },
    pasteText() {
      let stage = this.$store.state.stage;
      let layer = this.$store.state.layer;
      let currentIndex = layer?.children ? JSON.parse(JSON.stringify(layer?.children)).length : 1;
      this.$store.state.textColor = "black";
      let text = "";

      this.currentSelectedTextItems.forEach((item) => {
        text = text + "[" + item.sort + "]" + "\t" + (this.$store.state.hideStudentName == false ? item.studentName : "") + ": " + item.statement + "\n";
      });

      var pastText = new Konva.Label({
        x: stage.width() * 0.1 + Math.floor(Math.random() * 200),
        y: stage.height() * 0.2 + Math.floor(Math.random() * 200),
        name: "pastText",
        draggable: true,
        listening: true,
        text: text,
        uuid: this.$jsFn.getUUID(),
        index: currentIndex,
      });
      this.pasteTextTime++;
      const colors = ["rgb(255, 238, 139)", "rgb(211, 255, 139)", "rgb(139, 243, 255)", "rgb(255, 191, 215)"];
      const random = this.pasteTextTime % 4;

      pastText.add(
        new Konva.Tag({
          fill: colors[random],
          // pointerDirection: 'left',
          // pointerWidth: 20,
          // pointerHeight: 28,
          // lineJoin: 'round',
          shadowColor: "black",
          shadowBlur: 10,
          shadowOffsetX: 10,
          shadowOffsetY: 10,
          shadowOpacity: 0.2,
          name: "pastTextContent",
          draggable: false,
          listening: true,
        })
      );
      pastText.add(
        new Konva.Text({
          text: text,
          fontSize: 16,
          fill: this.$store.state.textColor,
          padding: 20,
          lineHeight: 1,
          width: 200,
          height: 200, //height 設為'auto' 可符合文字大小，但拉伸時抓不到實際高度
          name: "pastTextContent",
          draggable: false,
          listening: true,
        })
      );

      layer.add(pastText);
      this.$toolbox.saveUndoHistory("add", pastText); //儲存undo記錄
      layer.batchDraw();
      this.$store.state.textColor = "red";
      this.currentSelectedTextItems = [];
      this.$store.state.mode = "check";
      // console.log(stage)
    },

    startIRS() {
      this.$store.state.IRSswitch = true;
      if (this.currentIRS() == "irsImmediately" || this.currentIRS() == "irsText") {
        this.status === "default";
        this.current = "";
        this.currentSelectedTextItems = [];
        this.againanswer();
      }
      this.confirmCardShow = false;
    },

    stopIRS() {
      this.showSmartPickerBtn = false;
      this.$store.state.IRSswitch = false;
      if (this.currentIRS() == "irsImmediately") {
        this.$Message.info(this.$t("board['單選題已結束']"));
      } else {
        this.$Message.info(this.$t("board['文字題已結束']"));
      }

      this.$parent.$parent.$parent.sendNormalInCurrentPage();
    },
    currentIRS() {
      return this.$store.state.irsModel;
    },
    currentTextAns() {
      return this.$store.state.answervalue;
    },
    openSetTextAnspop() {
      this.showSmartPickerBtn = false;
      this.showTextAnspop = true;
    },
    close() {
      this.visible = false;
    },
    changeShow(e) {
      if (this.changeList[0] !== e) {
        this.changeList = [];
        this.changeList.push(e);
      } else {
        this.changeList = [];
      }
    },
    hiddenShow: function () {
      if (!this.$parent.$parent.$parent.isWaitingGptFeedbackDone) {
        this.$store.state.isShowCard = false;
        this.$store.state.isLightPieChart = true;
        this.$emit("close", "false");
        setTimeout(() => {
          this.$store.state.isLightPieChart = false;
        }, 2000);
      } else {
        this.$Message.info(this.$t('gptBox["等候關閉互動窗提示字"]'));
      }
    },
    /*查看学生*/
    studentss() {
      let studentnum = this.$store.state.students;
      this.iconnum = this.$store.state.students.length;
      let i = "";
      let answerdata = [];
      let noanswerdata = [];
      for (i = 0; i < studentnum.length; i++) {
        if (studentnum[i].option !== "" || studentnum[i].statement !== "") {
          answerdata.push(studentnum[i]);
        } else if (studentnum[i].option === "" && studentnum[i].statement === "") {
          noanswerdata.push(studentnum[i]);
        }
        studentnum[i].done = true;
      }
      this.status === "default";
      this.answernum = answerdata.length;
      this.noanswernum = noanswerdata.length;

      //处理当前的答案模式
      this.answerpatterns();
    },
    //根据设定答案的模式 来显示字母或数字
    answerpatterns() {
      let pattern = this.$store.state.currentAnsOption == "En" ? "letter" : "number";
      let studentDatas = this.$store.state.students;
      if (pattern === "letter") {
        for (var i = 0; i < studentDatas.length; i++) {
          if (studentDatas[i].option === "1" || studentDatas[i].option === "A") {
            studentDatas[i].option = "A";
          } else if (studentDatas[i].option === "2" || studentDatas[i].option === "B") {
            studentDatas[i].option = "B";
          } else if (studentDatas[i].option === "3" || studentDatas[i].option === "C") {
            studentDatas[i].option = "C";
          } else if (studentDatas[i].option === "4" || studentDatas[i].option === "D") {
            studentDatas[i].option = "D";
          } else if (studentDatas[i].option === "5" || studentDatas[i].option === "E") {
            studentDatas[i].option = "E";
          } else if (studentDatas[i].option === "6" || studentDatas[i].option === "F") {
            studentDatas[i].option = "F";
          } else if (studentDatas[i].option === "7" || studentDatas[i].option === "G") {
            studentDatas[i].option = "G";
          } else if (studentDatas[i].option === "8" || studentDatas[i].option === "H") {
            studentDatas[i].option = "H";
          } else if (studentDatas[i].option === "9" || studentDatas[i].option === "I") {
            studentDatas[i].option = "I";
          } else if (studentDatas[i].option === "0" || studentDatas[i].option === "J") {
            studentDatas[i].option = "J";
          }
        }
      } else if (pattern === "number") {
        for (var i = 0; i < studentDatas.length; i++) {
          if (studentDatas[i].option === "1" || studentDatas[i].option === "A") {
            studentDatas[i].option = "1";
          } else if (studentDatas[i].option === "2" || studentDatas[i].option === "B") {
            studentDatas[i].option = "2";
          } else if (studentDatas[i].option === "3" || studentDatas[i].option === "C") {
            studentDatas[i].option = "3";
          } else if (studentDatas[i].option === "4" || studentDatas[i].option === "D") {
            studentDatas[i].option = "4";
          } else if (studentDatas[i].option === "5" || studentDatas[i].option === "E") {
            studentDatas[i].option = "5";
          } else if (studentDatas[i].option === "6" || studentDatas[i].option === "F") {
            studentDatas[i].option = "6";
          } else if (studentDatas[i].option === "7" || studentDatas[i].option === "G") {
            studentDatas[i].option = "7";
          } else if (studentDatas[i].option === "8" || studentDatas[i].option === "H") {
            studentDatas[i].option = "8";
          } else if (studentDatas[i].option === "9" || studentDatas[i].option === "I") {
            studentDatas[i].option = "9";
          } else if (studentDatas[i].option === "0" || studentDatas[i].option === "J") {
            studentDatas[i].option = "0";
          }
        }
      }
      this.$store.state.students = studentDatas;
    },
    //排行榜初始化
    //Rankinit(){
    //    let studentrank = this.$store.state.students

    //},

    //精准学生人群
    accurate(e) {
      let studentArr = this.student;
      let answerstatus = this.Answerstatus;
      let i = "";
      if (e === "allstudents") {
        this.btnname = "allstudents";
        for (i = 0; i < studentArr.length; i++) {
          studentArr[i].done = true;
        }
      } else if (e === "unanswered-btn") {
        this.btnname = "unanswered-btn";
        for (i = 0; i < studentArr.length; i++) {
          if (studentArr[i].class === "studyboxone") {
            studentArr[i].done = true;
          } else {
            studentArr[i].done = false;
          }
        }
      } else if (e === "answerbtn") {
        this.btnname = "answerbtn";
        for (i = 0; i < studentArr.length; i++) {
          if (studentArr[i].class === "studybox") {
            studentArr[i].done = true;
          } else {
            studentArr[i].done = false;
          }
        }
      } else if (e === "answer-error") {
        this.btnname = "answer-error";
        for (i = 0; i < studentArr.length; i++) {
          if (studentArr[i].answerclass === "answer-error") {
            studentArr[i].done = true;
          } else {
            studentArr[i].done = false;
          }
        }
      } else if (e === "answercorrect") {
        this.btnname = "answercorrect";
        for (i = 0; i < studentArr.length; i++) {
          if (studentArr[i].answerclass === "answercorrect") {
            studentArr[i].done = true;
          } else {
            studentArr[i].done = false;
          }
        }
      }
    },
    // 滑块数据变化
    sliderfunc(e) {
      this.idlernum = e;
    },
    // 全屏按钮 样式变化
    styleadjust(e) {
      if (e === "default") {
        this.aspectRatio = "screen";
        this.current = "";

        this.fullscreen = "0";
        this.cssheightL = `${window.innerHeight * 0.8 - 60}`;
        this.$store.state.cssvalue = "1";
        this.$store.state.optionViewHeight = window.innerHeight * 0.8 - 60;
        this.$emit("styleObjScreen", this.styleObjScreen);
      } else if (e === "screen") {
        this.aspectRatio = "default";
        this.current = "";

        this.fullscreen = "0";
        this.cssheightL = "240";
        this.$store.state.optionViewHeight = 240;
        this.$store.state.cssvalue = "0";
        this.$emit("styleObjScreen", this.styleObjDefault);
      }
    },
    // 秀名牌||切换栏目
    listbtn(type) {
      this.showSmartPickerBtn = false;
      this.valuestyle();
      // if (e === 'rank') {
      //   this.rankinit()
      // } else {
      //   let rankstudent = this.$store.state.students
      //   rankstudent.sort(function(a, b) {
      //     return a.sort - b.sort
      //   })
      //   this.$store.state.students = rankstudent
      // }
      if (type === "default") {
        this.showhide(this.AnswerShowhide);
      }

      this.$store.state.IRSswitch = this.autoOff == true ? false : true;
      if (this.$store.state.IRSswitch == false) {
        this.$parent.$parent.$parent.sendNormalInCurrentPage();
      }

      if (this.current === type) {
        this.current = "";
        this.status = "default";
      } else {
        this.current = type;
        this.status = type;
        if (type == "text" && this.currentAns.trim() != "") this.showTextAns(); //文字題二次作答翻牌對答案
      }
    },
    //处理学生排行榜数据
    rankinit() {
      let rankstudent = this.$store.state.msgBody;
      if (rankstudent) {
        let namedata = [];
        let rankdata = [];
        for (var i = 0; i < rankstudent.length; i++) {
          let irsstudent = rankstudent[i].totalirs;
          for (var j = 0; j < irsstudent.length; j++) {
            namedata.push(irsstudent[j]);
          }
        }
        let studentData = JSON.parse(JSON.stringify(this.$store.state.students));
        console.log(studentData, "进入到排行榜的学生信息");
        for (var m = 0; m < studentData.length; m++) {
          let dataid = studentData[m].sort;
          let filterArr = namedata.filter((item) => item.sort === dataid).map((item) => item.score);
          let totalnum = filterArr.length > 1 ? filterArr.reduce((a, b) => a + b) : filterArr[0];
          rankdata.push(studentData[m]);
          rankdata[m].score = totalnum;
        }
        rankdata.sort(function (a, b) {
          return b.score - a.score;
        });
        if (rankdata.length > 3) {
          rankdata[0].rank = "1";
          rankdata[1].rank = "2";
          rankdata[2].rank = "3";
        }
        this.RankDatas = rankdata;
      } else {
        this.RankDatas = this.$store.state.students;
      }
    },
    // 设定答案后名牌变化
    ShowChangeCard() {
      let i = "";
      let student = this.$store.state.students;
      for (i = 0; i < student.length; i++) {
        if (student[i].option === 1) {
          student[i].option = "A";
        } else if (student[i].option === 2) {
          student[i].option = "B";
        } else if (student[i].option === 3) {
          student[i].option = "C";
        } else if (student[i].option === 4) {
          student[i].option = "D";
        }
      }
    },
    // 获取弹窗宽度大小
    getpoptipwidth(data) {
      this.poptipStyle = data;
    },
    //名牌show学生答案
    showhide(e) {
      let that = this;
      let timeout;
      clearTimeout(timeout);
      this.valuestyle();
      this.forceupdatenum++;
      // console.log(this.AnswerShowhide, this.Answerstatus)
      if (e === "AnswerHide") {
        this.$parent.$parent.$parent.answer();
        this.ShowHideanswer = require("../../assets/img/show-answer.png");
        // this.value1 = 20
        this.AnswerShowhide = this.student.findIndex(stu=>stu.option!="")!=-1?"AnswerShow":"AnswerShowOption";
      } else if (e === "AnswerShow") {
        this.$parent.$parent.$parent.answer();
        this.ShowHideanswer = require("../../assets/img/hide-answer.png");
        // this.value1 = 20
        this.AnswerShowhide = "AnswerShowOption"; //不管有沒有設答案都能看選項

        //等候狀態改變再設一次大小
        timeout = setTimeout(() => {
          that.valuestyle();
        }, 10);
      } else if (e === "AnswerShowOption") {
        // this.value1 = 20
        this.AnswerShowhide = "AnswerHide";
      }
    },

    showTextAns() {
      this.$store.state.answerstatus = 1;
      this.showTextAnspop = false;
      this.$store.state.answervalue = this.currentAns;
    },

    //重新作答
    againanswer() {
      let nowstudent = this.$store.state.students;
      console.log(nowstudent);
      //追查当页设定答案后 给学生的加分
      let answeryes = this.$store.state.answervalue;
      let status = this.$store.state.answerstatus;
      this.AnswerShowhide = "AnswerHide";
      this.ShowHideanswer = require("../../assets/img/hide-answer.png");
      //获取当前页数 重置缓存内数据
      let pagenumber = this.$store.state.carouselSlide;
      if (status === 1) {
        for (var y = 0; y < nowstudent.length; y++) {
          if (answeryes[0] == nowstudent[y].option) {
            if (nowstudent[y].score !== 0) {
              nowstudent[y].score = nowstudent[y].score - 1;
            }
          }
        }
      }
      //新開重新校正條圖X軸作答長度
      this.$store.state.AnswerSelect = ["A", "B", "C", "D"];
      this.$store.state.AnswerNumselect = ["1", "2", "3", "4"];

      //清理数据
      for (var i = 0; i < nowstudent.length; i++) {
        nowstudent[i].option = "";
        nowstudent[i].statement = "";
        nowstudent[i].class = "studyboxone";
        nowstudent[i].showcardclass = "brand";
        nowstudent[i].answerclass = "studyboxone";
        nowstudent[i].answerbrand = "brand";
        //nowstudent[i].statement=''
      }
      //重设基础值
      this.$store.state.answerstatus = 0;
      this.$store.state.noanswernum = nowstudent.length;
      this.$store.state.answernum = "0";
      this.$store.state.erroranswernum = "0";
      this.$store.state.rightanswernum = "0";
      this.$store.state.students = nowstudent;
      this.$parent.$parent.$parent.studentPercentages();
      this.$parent.$parent.$parent.answerSelect();
      this.status = "default";

      if (this.aspectRatio == "screen") {
        this.aspectRatio = "screen";
        this.current = "";

        this.fullscreen = "0";
        this.cssheightL = `${window.innerHeight * 0.8 - 60}`;
        this.$store.state.cssvalue = "1";
        this.$emit("styleObjScreen", this.styleObjScreen);
      }

      this.$store.state.IRSswitch = true;
      this.$store.state.answervalue = "";
      this.$parent.$parent.$parent.switchoverpdf(pagenumber);

      //关于irs即问即答和文字
      var times = new Date().getTime();
      var msgInitiative = {
        action: "State.Get",
        clientType: "HiTeachCC",
        sender: sessionStorage.getItem("user").sub,
        timestamp: times,
        waitReturn: false,
        payload: "",
      };
      this.$parent.$parent.$parent.singnalRMessage(msgInitiative, "8888", "", this.$store.state.irsModel);
      this.$Message.success(this.$t("board.optionView['答案已重新開啟！請重新作答']"));
    },
    //发言可变便利贴
    dragOptions1() {
      return {
        animation: 0,
        handleChange: "",
        inputChanged: "",
        activeNames: "",
        group: {
          name: "description",
          pull: "clone",
          put: false,
        },
        ghostClass: "ghost",
      };
    },
    statementToimage(val) {
      console.log("开始移动了");
      html2canvas(document.getElementById("statementBox"), {
        //backgroundColor: null,
      }).then((canvas) => {
        console.log(document.getElementById("statementBox"), "元素获取");
        //document.getelementbyclassname(tableid).innerHTML = "";
        const img = canvas.toDataURL();
        var imageObj = new Image();
        imageObj.src = img;
        console.log(imageObj.src, "图片的值");
        this.studentToimageurl = imageObj.src;
      });
      this.isDragging == true;
    },
    onMove() {
      console.log("在移动，在移动");
    },
    stickupend(val) {
      console.log(val, "这是前面页的X值");
      let positionw = val.originalEvent.x - 125;
      let positionh = val.originalEvent.y - 50;
      console.log(val.srcElement.innerText, "文本内容");
      console.log(positionw, positionh, "坐标位置");
      //this.$store.state.position.status = '1'
      //this.$store.state.position.x = positionw
      //this.$store.state.position.y = positionh
      let layers = this.$store.state.layer;
      //let starme = val.item.innerText.split('\n')
      //console.log(starme, '分割的数组')
      //this.$store.state.position.name = starme[0]
      //this.$store.state.position.value = starme[1]

      var imageObj = new Image();
      imageObj.src = this.studentToimageurl;
      imageObj.setAttribute("crossOrigin", "anonymous");
      imageObj.onload = function () {
        var stickyImage = new Konva.Image({
          x: positionw,
          y: positionh,
          image: imageObj,
          width: imageObj.width,
          height: imageObj.height,
          draggable: true,
        });
        // add the shape to the layer
        //group.add(tableInfo);
        layers.add(stickyImage);
        //group.zIndex(paragraphall.index+1)
        layers.batchDraw();
        //group.batchDraw();
      };
      //获取便利贴数据
      let stickDatas = this.$store.state.stickyNoteDatas;
      console.log(stickDatas, "便利贴Data");
      let textinfo = val.srcElement.innerText;
      var timestamp = new Date().getTime();
      var stuName = textinfo.substr(textinfo.indexOf("--") + 2);
      var stuContent = textinfo.substr(0, textinfo.indexOf("--") - 1);
      console.log(stuName, "出现的位置", stuContent, "文本");
      if (stickDatas.length !== 0) {
        for (var i = 0; i < stickDatas.length; i++) {
          if (stickDatas[i].alltext !== textinfo) {
            stickDatas.push({ studentName: stuName, textContent: stuContent, alltext: textinfo, time: timestamp });
          }
        }
      } else {
        stickDatas.push({ studentName: stuName, textContent: stuContent, alltext: textinfo, time: timestamp });
      }
      this.$store.state.stickyNoteDatas = stickDatas;
    },
    detailbtn(e) {
      console.log(e, "传输的内容");
      this.alert = true;
      var texttatal = e.target.innerText;
      var name = texttatal.substr(texttatal.indexOf("--") + 2);
      var content = texttatal.substr(0, texttatal.indexOf("--") - 1);
      this.deatilName = name;
      this.detailContent = content;
      console.log(name, content, "内容");
      //let num = e;
      //let textdata = this.$store.state.irsBack
      //this.detailtext = textdata[num].value
      //this.detailname = textdata[num].studentName
      //this.detailtime = textdata[num].sendTime
    },
    //阈值样式
    valuestyle() {
      let value = this.value1;
      let dwidth = this.$refs.getheight ? this.$refs.getheight.offsetWidth : this.dwidthOld;
      this.dwidthOld = dwidth;
      let pwidth = "";
      let pmarginleft = "";
      if (value === 10) {
        pwidth = 25;
        pmarginleft = 5;
        this.fontSizeValue = 16;
      } else if (value === 20) {
        pwidth = 80;
        pmarginleft = 10;
        this.fontSizeValue = 36;
      } else if (value === 30) {
        pwidth = 160;
        pmarginleft = 20;
        this.fontSizeValue = 72;
      }
      let widthpercent = pwidth / value;
      let marginpercent = pmarginleft / value;
      let valueone = (widthpercent / 100) * dwidth;
      let valuetwo = (marginpercent / 100) * dwidth;
      this.widthvalue = valueone;
      this.marginvalue = valuetwo;
      let FinalfontSize = (this.fontSizeValue * (window.innerWidth / 1920)).toFixed(0);
      setTimeout(()=>{ // 延遲避免無法抓到
      for (let i = 0; i < document.getElementsByClassName("studyboxone").length; i++) {
        document.getElementsByClassName("studyboxone")[i].style.fontSize = FinalfontSize + "px";
        document.getElementsByClassName("studyboxone")[i].style.transition = "ease-in"
      }
      for (let i = 0; i < document.getElementsByClassName("studybox").length; i++) {
        document.getElementsByClassName("studybox")[i].style.fontSize = FinalfontSize + "px";
        document.getElementsByClassName("studybox")[i].style.transition = "ease-in"
      }
      for (let i = 0; i < document.getElementsByClassName("answer-default").length; i++) {
        document.getElementsByClassName("answer-default")[i].style.fontSize = FinalfontSize + "px";
        document.getElementsByClassName("answer-default")[i].style.transition = "ease-in"
      }
      for (let i = 0; i < document.getElementsByClassName("answer-default-show").length; i++) {
        document.getElementsByClassName("answer-default-show")[i].style.fontSize = FinalfontSize + "px";
        document.getElementsByClassName("answer-default-show")[i].style.transition = "ease-in"
      }
    },)
    },
    //圆角样式
    angleshow() {
      this.angleicon = !this.angleicon;
    },

    hideangleicon() {
      this.angleicon = false;
    },
    watchcarouselSlide() {
      return that.$store.state.carouselSlide;
    },
    showName(item) {
      //console.log(item,this.status,this.currentIRS())
      this.currentstuItem = item;
      this.currentstuItemShow = true;
      setTimeout(() => {
        this.currentstuItemShow = false;
      }, 8000);
      // console.log(document.getElementById(item.sort+'A').style.offsetLeft)
      // this.currentstuItemleft=(item.sort+1)%this.value1*(30/this.value1)
    },
    selectShowName(item) {
      this.showName(item);

      if (this.currentSelectedTextItems.includes(item) == false) {
        this.currentSelectedTextItems.push(item);
      } else {
        this.currentSelectedTextItems.splice(this.currentSelectedTextItems.indexOf(item), 1);
      }
    },
    studentOptionLength(option) {
      let optionLength = 0;
      this.student.forEach((element) => {
        if (element.option === option) {
          optionLength++;
        }
      });
      return optionLength;
    },
    triggerGPTByOpenBox() {
      this.$store.state.mqtt.mqttIoTData.isGpt=1
      this.$store.dispatch('mqtt/saveMqttIoTsessionStorage')

      let allText = "";
      
      this.student.forEach((stu) => {
        if (stu.statement != "") {
          allText = allText +  "[" + stu.sort + "]" + stu.studentName + ":\t" + stu.statement + ",\n";
        }
      });
      this.$parent.$parent.$parent.$refs.gptBox.currentText = allText
      this.$parent.$parent.$parent.$refs.gptBox.currentGPTAns=''
      this.$parent.$parent.$parent.$refs.gptBox.cancelWaitingGPTRes = false
      this.$parent.$parent.$parent.showGptBox = true
    },
    triggerWordCloud() {
      let that = this;
      if (this.current === "cloud") {
        this.current = "";
        this.status = "default";
      } else {
        this.current = "cloud";
        this.status = "cloud";
        this.wordCloudFreq = [];
        this.styleadjust("default");
        that.$q.loading.show();
        let wordcloudiframe = document.getElementById("wordcloudiframe");
        let wordCloudText = "";
        this.student.forEach((stu) => {
          wordCloudText = wordCloudText + stu.statement;
        });
        console.log(wordCloudText);

        wordcloudiframe.contentWindow.postMessage(
          //要傳遞的資料，可以是字串也可以是物件
          { height: parseInt(that.cssheightL) - 80, wordCloudText: wordCloudText, parentLang: that.lang, action: "draw" },
          //指定接受資料的對象，雖然可以用 * 全面開放，但不建議
          that.hiteachccorigin + that.wordcloudDic
        );
        this.$store.state.mqtt.mqttIoTData.isWordCloud=1
        this.$store.dispatch('mqtt/saveMqttIoTsessionStorage')
      }
    },
    showWordCloudTableSmartPickBtn(worditem, event) {
      let filterOptStudents = [];
      filterOptStudents = this.$store.state.students.filter((stu) => stu.statement.includes(worditem.name));

      this.$store.state.candidates_turnTable_InSmart = filterOptStudents;
      this.$store.state.candidates_turnTable_InSmartOriginal = [...filterOptStudents];
      this.$store.state.turnTableInSmartAction = { PickupType: "PickupOption", PickupOption: 1 };
      this.handleSmartPickerBtn(event.clientX, event.clientY);
      let candidate = [];
      candidate = filterOptStudents.map((stu) => stu.sort);
      console.log(candidate, "文字雲智慧挑人候選");
    },
    requestWordCloudImg() {
      let wordcloudiframe = document.getElementById("wordcloudiframe");
      this.$q.loading.show();
      wordcloudiframe.contentWindow.postMessage(
        //要傳遞的資料，可以是字串也可以是物件
        { action: "getImage" },
        //指定接受資料的對象，雖然可以用 * 全面開放，但不建議
        this.hiteachccorigin + this.wordcloudDic
      );
    },
    pasteWordCloudImg(e) {
      let that = this;
      let blobUrl = this.classInfo.blob;
      let stage = this.$store.state.stage;
      let layer = this.$store.state.layer;
      this.$store.state.mode = "check";
      let times = new Date().getTime();
      //使用board層的函式
      let file = this.$parent.$parent.$parent.dataURLtoFile(e.data.base64_Img, times + "wordCloud.jpg");

      this.$parent.$parent.$parent
        .uploadResourceFile(file, "ObjSrcImage")
        .then((res) => {
          let fileUrl = res.url;
          console.log(fileUrl + "?" + blobUrl.sas_read, "上傳 文字雲圖片 成功");
          Konva.Image.fromURL(fileUrl + "?" + blobUrl.sas_read, function (image) {
            let scaleRatio = image.width() > stage.width() ? stage.width() / image.width() / 2 : 0.8;
            // image is Konva.Image instance
            image.setAttrs({
              x:
                stage
                  .getAbsoluteTransform()
                  .copy()
                  .invert()
                  .point({ x: stage.width() / 3, y: stage.height() / 5 }).x - Math.floor(Math.random() * 200),
              y: stage
                .getAbsoluteTransform()
                .copy()
                .invert()
                .point({ x: stage.width() / 3, y: stage.height() / 5 }).y,
              width: image.width() * scaleRatio,
              height: image.height() * scaleRatio,
              draggable: true,
              src: fileUrl,
            });

            // stage.find('Transformer').destroy()
            let tr = new Konva.Transformer({
              anchorStroke: "#00a6ff",
              anchorFill: "#fff",
              anchorSize: 12,
              anchorCornerRadius: 5,
              anchorStrokeWidth: 2,
              borderStroke: "#6ac9fc",
              borderStrokeWidth: 2,
              borderDash: [3, 3],
              padding: 10,
              name: "default",
            });
            that.$toolbox.removeTransformer();
            layer.add(image);
            layer.add(tr);
            tr.nodes([image]);
            layer.draw();
            that.styleadjust("screen"); //縮小視窗
            that.$q.loading.hide();
          });
        })
        .catch((err) => {
          that.$q.loading.hide();
        });
    },
  },
  watch: {
    windowWidth() {
      this.valuestyle();
    },
    value1: function (value) {
      this.$store.state.tempIRSBlockSize = value.toString();
    },

    aspectRatio: function (value) {
      this.valuestyle();
      if (value == "default") {
        this.cssheightL = "240";
        (this.$store.state.optionViewHeight = 240), (this.poptipStyle = "poptipStyleWidth poptipTop");
      } else {
        this.cssheightL = `${window.innerHeight * 0.8 - 60}`;
        this.$store.state.optionViewHeight = window.innerHeight * 0.8 - 60;
        this.poptipStyle = "poptipStyleWidth poptipBottom";
      }
    },

    watchStudents: {
      handler(e) {
        if (e) {
          this.valuestyle();
          console.log(e, "监听到学生变化");
          this.student = this.$store.state.students;
          this.iconnum = this.$store.state.students.length;
          if (this.current === "") {
            this.studentss();
            this.valuestyle(); //偵測更新樣式
          }
        }
      },
      deep: true,
    },
    Answerstatusone(a) {
      this.Answerstatus = a;
      // this.status = 'default'

      //this.ShowChangeCard()
    },

    /*监听设定正确答案*/
    watchAnswervalue(a) {
      this.settinganswer = a;
    },
    /*检测答题人数*/
    watchAnswernum(e) {
      console.log(e, "监听到答题人数");
      this.answernum = e;
    },
    /*检测未答题人数*/
    watchNoAnswernum(e) {
      console.log(e, "监听未答题人数");
      this.noanswernum = e;
    },
    /*监听正确答案人数*/
    watchRightAnswernum(e) {
      this.rightanswernum = e;
    },
    /*监听错误答案人数*/
    watchErrorAnswernum(e) {
      this.erroranswernum = e;
    },
    /*监听poptipbox*/
    watchpoptip(e) {
      if (e === false) {
        this.angleicon = false;
      }
    },
    /*监听设定答案模式*/
    watchAnswerpattern(e) {
      this.AnswerIconstyle = e;
    },
  },
  beforeDestroy() {
    clearTimeout(this.smartPickerBtnTimeout);
    this.smartPickerBtnTimeout = "";
  },
};
</script>
<style lang="less">
@import "../../assets/css/OptionView.less";
.ivu-select {
  position: relative;
  min-width: 200px;
}

.ivu-select-item {
  color: black !important;
  font-size: 16px !important;
}

// .ivu-select-dropdown-transfer {
//   left: 40px !important;
// }

.ivu-select-selection {
  border: 1px solid gray;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0);

  .ivu-select-selected-value {
    color: #000 !important;
    font-size: 16px !important;
  }
}
</style>
